import React, { Component } from 'react'

class TwoColumnContent extends Component {
	render() {
		const { left_column_title, left_column_content, right_column_title, right_column_content } = this.props.content;

		return (
			<div className="block two-column-content-block">
				<div className="block-inner">
					<div className="left-column block-content">
						{ left_column_title !== '' ? <h2 class="block-title">{ left_column_title }</h2> : '' }
						{ left_column_content !== "" ? <div className="block-content" dangerouslySetInnerHTML={{__html: left_column_content}}></div> : ''}
					</div>
					<div className="right-column block-content">
						{ right_column_title !== '' ? <h2 class="block-title">{ right_column_title }</h2> : '' }
						{ right_column_content !== "" ? <div className="block-content" dangerouslySetInnerHTML={{__html: right_column_content}}></div> : ''}
					</div>
				</div>
			</div>
		)
	}
}

export default TwoColumnContent