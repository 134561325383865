import React, { Component } from 'react'

class ContentOnImage extends Component {
	render() {
		let { image, title, content, link_text, link_url } = this.props.content;

		return (
			<div className="block content-on-image">
				<div className="block-inner" style={{ backgroundImage: 'url(' + image.thumbnailUrl + '?w=1640&h=600&fit=crop)'}}>
					<div className="block-main">
						{ title !== '' ? <h2 class="block-title">{ title }</h2> : ''}
						{ content !== '' ? <div class="block-content">{ content }</div> : ''}
						{ link_url !== '' ? <a class="button" href={link_url}>{ link_text }</a> : ''}
					</div>
				</div>
			</div>
		)
	}
}

export default ContentOnImage