import {
	CLEAR_ERROR,
	ADD_ERROR
} from './actionTypes'

import { logoutUser } from 'admin/modules/Auth/actions'

export function clearError() {
	return {
		type: CLEAR_ERROR
	}
}

export function addError(error) {
	return {
		type: ADD_ERROR,
		error: error
	}
}

export function handleErrors(error) {
	return dispatch => {
		var errorMessage = "Unknown Error";
		if(error.response) {
			if(error.response.status === 401){
				dispatch(logoutUser());
				throw error;
			}
			if(error.response.status === 404){
				throw error;
			}
			if(error.response.status === 422){
				throw error;
			}
			if(error.response.data.message)
				errorMessage = error.response.data.message;
			else
				errorMessage = "Unknown Error";
		} else if(error.message) {
			errorMessage = error.message;
		} else {
			errorMessage = "Unknown Error";
		}
		dispatch(addError(errorMessage));
		throw error;
	}
}