import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class FeatureBuckets extends Component {
	render() {
		let { buckets } = this.props.content;

		return (
			<div className="block feature-buckets-block">
				{ buckets.map((bucket, i) => {
					let { title, subtitle } = bucket;

					return (
						<div className="bucket">
							{ title !== '' ? <p class="bucket-title">{ title }</p> : ''}
							{ subtitle !== '' ? <p class="bucket-subtitle">{ subtitle }</p> : ''}
						</div>
					)
				})}
			</div>
		)
	}
}

export default withRouter(FeatureBuckets)