import React, { Component } from 'react'

class TwoColumnOnImage extends Component {
	render() {
		let { image, title, left_column_content, right_column_content } = this.props.content;

		return (
			<div className="block two-column-on-image-block">
				<div className="block-inner" style={{ backgroundImage: 'url(' + image.thumbnailUrl + '?w=1640&h=600&fit=crop)'}}>
					<div className="block-main">
						{ title !== '' ? <h2 class="block-title">{ title }</h2> : ''}
						<div className="block-content-wrapper">
							{ left_column_content !== "" ? <div className="block-content" dangerouslySetInnerHTML={{__html: left_column_content}}></div> : ''}
							{ right_column_content !== "" ? <div className="block-content" dangerouslySetInnerHTML={{__html: right_column_content}}></div> : ''}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default TwoColumnOnImage