import React, { Component } from 'react';

class Pagination extends Component {
	render() {
		const firstPage = parseInt(this.props.currentPage);
		const lastPage = parseInt(this.props.totalPages);
		const delta = 2;
		const left = firstPage - delta;
		const right = firstPage + delta  + 1;

		let range = [];
		for(let i = 1; i <= lastPage; i++) {
			if(i === 1 || i === lastPage || (i >= left && i < right))
				range.push(i)
		}
		
		let rangeWithDots = [];
		let last;
		for(let i of range) {
			if(last) {
				if(i - last === 2)
					rangeWithDots.push(last + 1)
				else if(i - last !== 1)
					rangeWithDots.push('...')
			}
			rangeWithDots.push(i);
			last = i;
		}
		
		return (
			<div className="pagination">
				{
					rangeWithDots.map((page, i) => {
						if(Number.isInteger(page))
							return <button onClick={() => this.props.onPageClick(page)} className={"page-button" + (page === this.props.currentPage ? ' is-active' : '')} key={i}>{page}</button>
						return <span className="page-ellipsis" key={i}>{page}</span>
					})
				}
			</div>
		)
	}
}

export default Pagination;