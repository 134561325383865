import {
	CLEAR_ERROR,
	ADD_ERROR
} from './actionTypes'

export const errorMessage = (state = '', action) => {
	switch (action.type) {
		case CLEAR_ERROR:
			return ''
		case ADD_ERROR:
			return action.error;
		default:
			return state
	}
}