import React, {Component} from 'react'
import moment from 'moment'

class CalendarEvent extends Component {
	render() {
		let event = this.props.event
		return (
			<div className="event-bucket">
				<div className="event-bucket-details">
					{event.event.featuredimage !== "" && event.event.featuredimage !== null ? <div className="event-image" style={{ backgroundImage: 'url(' + event.event.featuredimage.thumbnailUrl + '?w=350&h=200&fit=crop)'}}></div> : ''}
					<div className="event-main">
						<div className="event-date">{ moment(event.start).format('MMMM D, YYYY | h:mmA') }</div>
						{event.event.title !== "" ? <h2 className="event-title">{event.event.title}</h2> : ''}
					</div>
				</div>
				<div className="button-group">
					<a className="event-link button small" href={'/events/' + event.event.slug}>More Info</a>
					{event.tickets_url !== "" ? <a className="event-link button secondary small" href={event.tickets_url}>Buy Tickets</a> : ''}
				</div>
			</div>
		)
	}
}

export default CalendarEvent