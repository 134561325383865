import React, { Component } from 'react';
import Input from 'core/components/form/Input'

export const fieldDefaults = {
	'text': '',
	'link_text': '',
	'link_url': ''
}

export class UpcomingEvents extends Component {
	render() {
		return (
			<div className="block">
				<p className="block-title">Upcoming Events</p>
				<div className="block-fields">
					<Input type="text" name="title" label="Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.title} />
					<div className="field-group">
						<Input type="text" name="link_text" label="Link Text" onInputChange={this.props.onChange} value={this.props.content.link_text} />
						<Input type="text" name="link_url" label="Link URL" onInputChange={this.props.onChange} value={this.props.content.link_url} />
					</div>
				</div>
			</div>
		);
	}
}