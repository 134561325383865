import React, {Component} from 'react'
import { connect } from 'react-redux'
import Dashboard from '../components/Dashboard'
import { logoutUser } from 'admin/modules/Auth/actions'
import { getLoggedInUser } from 'admin/modules/Auth/selectors'
import { userHasPermission } from 'admin/modules/Auth/selectors'
import { getSettings } from 'admin/modules/Settings/actions'

class DashboardContainer extends Component {
    componentWillMount() {
        this.props.getSettings()
    }

    render() {
        return (
            <Dashboard {...this.props} />
        )
    }
}

const mapStateToProps = (state = {}) => {
    // console.log(state.router.location);
    return {
    	user: getLoggedInUser(state),
    	showAudits: userHasPermission(state, 'audit:view'),
    	showUsers: userHasPermission(state, 'user:view'),
        showPages: userHasPermission(state, 'page:view'),
        showMenus: userHasPermission(state, 'menu:view'),
        showEvents: userHasPermission(state, 'event:view'),
        showForms: userHasPermission(state, 'form:view'),
        showPosts: userHasPermission(state, 'post:view')
    }
};

const mapDispatchToProps = dispatch => {
	return {
        getSettings: () => {
            dispatch(getSettings())
        },
		onLogoutClick: creds => {
			dispatch(logoutUser(creds))
		}
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)