import React, {Component} from 'react'
import { getActiveEventDates } from 'admin/modules/Event/api'
import EventCalendar from '../components/EventCalendar'

class EventCalendarContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			items: [],
	    	isLoading: false
		}

		this.loadEvents = this.loadEvents.bind(this)
	}

	componentDidMount() {
		this.loadEvents();
	}

	async loadEvents() {
		this.setState({
			isLoading: true
		})

		let data = {
			perPage: 0
		}

	    await getActiveEventDates(data)
	    	.then(response => {
	    		this.setState({
					isLoading: false,
					items: response.data.data,
				})
	    	})
	    	.catch(error => {
		    	this.setState({
					isLoading: false
				})
	    	}) 
	}

	render() {
		return (
			<EventCalendar items={this.state.items} isLoading={this.state.isLoading} />
		)
	}
}

export default EventCalendarContainer