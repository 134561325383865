import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import ImageUpload from 'core/components/form/ImageUpload'
import WYSIWYG from 'core/components/form/WYSIWYG'
import Select from 'core/components/form/Select'

export const fieldDefaults = {
	'image': {},
	'image_side': 'left',
	'title': '',
	'content': ''
}

export class ImageText extends Component {
	render() {
		// let imageID = 0;
		// if(this.props.content.image !== undefined)
		// 	imageID = this.props.content.image._id
		return (
			<div className="block">
				<p className="block-title">Image Text</p>
				<div className="block-fields">
					<div className="field-group">
						<Select name="image_side" label="Image Side" onInputChange={this.props.onChange} value={this.props.content.image_side}>
							<option value="left">Left</option>
							<option value="right">Right</option>
						</Select>
					</div>
					<ImageUpload name="image" onInputChange={(name, value) => this.props.onChange(name, value)} value={this.props.content.image._id} />
					<Input type="text" name="title" label="Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.title} />
					<WYSIWYG name="content" label="Content" onInputChange={this.props.onChange} value={this.props.content.content} />
				</div>
			</div>
		);
	}
}