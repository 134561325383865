import React, { Component } from 'react';
import Field from './Field'
import ReactDatePicker from "react-datepicker";
import moment from 'moment'

import "react-datepicker/dist/react-datepicker.css";

class DatePicker extends Component {
	render() {
		// const { label, errors, removeFocus, ...rest} = this.props
		// console.log(this.props.value)
		let selected = null;
		if(this.props.value !== null) {
			selected = moment(this.props.value).toDate();
			if(selected == 'Invalid Date')
				selected = new Date();
		}
		return (
			<Field {...this.props} onChange={(date) => this.props.onInputChange(this.props.name, moment(date).format('YYYY-MM-DD HH:mm:ss'))}>
				<ReactDatePicker
					selected={selected}
				/>
			</Field>
		)
	}
}

export default DatePicker;