import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import WYSIWYG from 'core/components/form/WYSIWYG'

export const fieldDefaults = {
	'left_column_title': '',
	'left_column_content': '',
	'right_column_title': '',
	'right_column_content': '',
}

export class TwoColumnContent extends Component {
	render() {
		return (
			<div className="block">
				<p className="block-title">Two Column Content</p>
				<div className="block-fields">
					<Input type="text" name="left_column_title" label="Left Column Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.left_column_title} />
					<WYSIWYG name="left_column_content" label="Left Column Content" onInputChange={this.props.onChange} value={this.props.content.left_column_content} />
					<Input type="text" name="right_column_title" label="Right Column Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.right_column_title} />
					<WYSIWYG name="right_column_content" label="Right Column Content" onInputChange={this.props.onChange} value={this.props.content.right_column_content} />
				</div>
			</div>
		);
	}
}