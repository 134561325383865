import React, { Component } from 'react'

class ImageText extends Component {
	render() {
		const { title, image, content, image_side } = this.props.content;

		return (
			<div className={"block image-text-block " + image_side }>
				<div className="block-inner">
					{image !== "" && image !== undefined ? <img className="block-image" src={image.thumbnailUrl + '?w=950&h=550&fit=crop'} /> : ''}
					<div className="block-main">
						{title !== "" ? <h2 className="block-title">{title}</h2> : ''}
						{content !== "" ? <div className="block-content" dangerouslySetInnerHTML={{__html: content}}></div> : ''}
					</div>
				</div>
			</div>
		)
	}
}

export default ImageText