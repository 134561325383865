import { call } from 'API'

export function getPost(id) {
	return call('posts/' + id, 'get', {}, true);
}

export function getPosts(data) {
	return call('posts', 'get', data, true);
}

export function savePost(id, data) {
	return call('posts/' + id, 'put', data, true);
}

export function createPost(data) {
	return call('posts', 'post', data, true);
}

export function deletePost(id) {
	return call('posts/' + id, 'delete', {}, true);
}

export function getActivePosts(data) {
	return call('posts/active', 'get', data, false);
}

export function getPostBySlug(slug) {
	let data = {
		'slug': slug
	}
	return call('posts/slug', 'get', data, false);
}