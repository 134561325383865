import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import DatePicker from 'core/components/form/DatePicker'

import { faStar } from '@fortawesome/free-regular-svg-icons'
import { faStar as faSolidStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import '../assets/scss/EventDateForm.scss'

class EventDateForm extends Component {
	render() {
		let item = this.props.item;

		return (
			<div className="item-main event-date-form">
				<div className="field-group">
					<div className="featured" onClick={() => this.props.onInputChange('featured', !item.featured)}>
						{item.featured ?
							<FontAwesomeIcon icon={faSolidStar} size="lg" />
							:
							<FontAwesomeIcon icon={faStar} size="lg" />
						}
					</div>
					<DatePicker name="start" label="Start" onInputChange={this.props.onInputChange} value={item.start} showTimeSelect />
					<DatePicker name="end" label="End" onInputChange={this.props.onInputChange} value={item.end}  showTimeSelect />
					<Input type="text" name="tickets_url" label="URL" onInputChange={this.props.onInputChange} value={item.tickets_url} />
				</div>
			</div>
		)
	}
}

export default EventDateForm;