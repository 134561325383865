import React, { Component } from 'react'
import Edit from 'admin/components/EditForm'
import Create from 'admin/components/CreateForm'
import PostForm from '../components/PostForm'
import FormWrapper from 'core/components/form/FormWrapper'
import { savePost, createPost, deletePost, getPost } from '../api'

const fields = [
	'title',
	'slug',
	'content',
	'publish_start',
	{
		name: 'featuredimage',
		default: 0,
		parseExisting: value => {
			if(value !== null)
				return value._id
			return 0
		}
	}
]
const EditForm = FormWrapper(Edit, fields)
const CreateForm = FormWrapper(Create, fields)

class PostEditFormContainer extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
			isLoading: false,
			apiErrors: [],
			post: undefined,
			saveSuccess: false,
			deleteSuccess: false,
			preview: false
		}

		this.save = this.save.bind(this);
		this.create = this.create.bind(this);
		this.delete = this.delete.bind(this);
		this.getPost = this.getPost.bind(this);
		this.validate = this.validate.bind(this);
		this.togglePreview = this.togglePreview.bind(this);
	}

	componentWillMount() {
		const formID = this.props.match.params.id;
		if(formID !== undefined)
			this.getPost(formID);
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.match.params.id !== nextProps.match.params.id){
			if(nextProps.match.params.id !== undefined)
				this.getPost(nextProps.match.params.id);
		}
	}

	validate(name, label, value) {

		let errors = [];
		if(name === 'name' || name ==='start') {
			if(value.length === 0) {
				errors.push(label + " is required.");
			}
		}

		return errors;
	}

	handleErrors(error) {
		if(error.response && error.response.status === 422) {
	    	this.setState({
				isLoading: false,
				saveSuccess: false,
				apiErrors: error.response.data.errors
			})
		} else {
	    	this.setState({
				isLoading: false,
				saveSuccess: false
			})
	    }
	}

	handleSuccess(response) {
		this.setState({
			isLoading: false,
			saveSuccess: true,
			post: response.data.data
		})
	}

	togglePreview() {
		this.setState({ preview: !this.state.preview })
	}

	async save(data) {
		this.setState({
			isLoading: true,
			saveSuccess: false
		})

	    await savePost(this.props.match.params.id, data)
	    	.then(response => {
	    		this.handleSuccess(response);
	    	})
	    	.catch(error => {
	    		this.handleErrors(error);
	    	})
	}

	async create(data) {
		this.setState({
			isLoading: true,
			saveSuccess: false
		})

	    await createPost(data)
	    	.then(response => {
	    		this.handleSuccess(response);
	    	})
	    	.catch(error => {
		    	this.handleErrors(error);
	    	})
	}

	async delete() {
		this.setState({
			isLoading: true,
			deleteSuccess: false
		})

	    await deletePost(this.props.match.params.id)
	    	.then(response => {
	    		this.setState({
					isLoading: false,
					deleteSuccess: true
				})
	    	})
	    	.catch(error => {
		    	this.setState({
					isLoading: false,
					deleteSuccess: false
				})
	    	})
	}

	async getPost(id) {
		this.setState({
			isLoading: true
		})

	    await getPost(id)
	    	.then(response => {
	    		this.setState({
					isLoading: false,
					post: response.data.data
				})
	    	})
	    	.catch(error => {
		    	this.setState({
					isLoading: false
				})
	    	})
	}

	render() {
		let buttons = [
			{
				'title': 'Preview',
				'onClick': this.togglePreview
			}
		];

		return (
			<div>
				{
					this.props.match.params.id ?
					<EditForm
						id={this.props.match.params.id}
						getExisting={this.getPost}
						validate={this.validate} 
						name="Post"
						editValues={this.state.post}
						backLink="/admin/dashboard/posts"
						isLoading={this.state.isLoading}
						apiErrors={this.state.apiErrors}
						saveSuccess={this.state.saveSuccess}
						deleteSuccess={this.state.deleteSuccess}
						onSubmit={this.save}
						onDelete={this.delete}
						hideStatuses={true}
						buttons={buttons}
					>
						<PostForm preview={this.state.preview} togglePreview={this.togglePreview} />
					</EditForm>
					:
					<CreateForm 
						validate={this.validate} 
						name="Post"
						editValues={this.state.post}
						backLink="/admin/dashboard/posts"
						editorLink="/admin/dashboard/post/editor/"
						isLoading={this.state.isLoading}
						apiErrors={this.state.apiErrors}
						saveSuccess={this.state.saveSuccess}
						onSubmit={this.create}
						hideStatuses={true}
						buttons={buttons}
					>
						<PostForm preview={this.state.preview} togglePreview={this.togglePreview} />
					</CreateForm>
				}
			</div>
		)
	}
}

export default PostEditFormContainer