import React, { Component } from 'react';
import DatePicker from 'core/components/form/DatePicker'
import Input from 'core/components/form/Input'
import ImageUpload from 'core/components/form/ImageUpload'
import WYSIWYG from 'core/components/form/WYSIWYG'
import MultiRowWrapper from 'core/components/form/MultiRowWrapper'
import EventDates from './EventDates'
import moment from 'moment'

import Event from 'frontend/modules/Event/components/Event'

const EventDatesField = MultiRowWrapper(EventDates, {
	featured: '',
	start: '',
	end: '',
	tickets_url: ''
});

class EventForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			featuredimage: null,
			secondaryimage: null
		}
	}

	setImageState(name, value) {
		this.setState({
			[name]: value
		})
		this.props.onInputChange(name, value._id)
	}

	render() {
		const { values, errors } = this.props
		return (
			<div className="main-content">
				<form className="login-form" onSubmit={this.props.onSubmit}>
					<fieldset>
						<p className="form-title">Event</p>
						<Input type="text" name="title" label="Title" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.title} errors={errors.title} />
						<Input type="text" name="slug" label="Slug" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.slug} errors={errors.slug} />
						<WYSIWYG name="content" label="Content" onInputChange={this.props.onInputChange} errors={errors.content} value={values.content} />
					    <ImageUpload name="featuredimage" onInputChange={(name, value) => this.setImageState(name, value)} errors={errors.featuredimage} value={values.featuredimage} />
					    <ImageUpload name="secondaryimage" onInputChange={(name, value) => this.setImageState(name, value)} errors={errors.secondaryimage} value={values.secondaryimage} />  
					    <div className="field-group">
					    	<Input type="text" name="link_text" label="Link Text" onInputChange={this.props.onInputChange} errors={errors.link_text} value={values.link_text} />
					    	<Input type="text" name="link_url" label="Link URL" onInputChange={this.props.onInputChange} errors={errors.link_url} value={values.link_url} />
					    </div>
					    <div className="field-group">
					    	<Input type="text" name="secondary_link_text" label="Secondary Link Text" onInputChange={this.props.onInputChange} errors={errors.secondary_link_text} value={values.secondary_link_text} />
					    	<Input type="text" name="secondary_link_url" label="Secondary Link URL" onInputChange={this.props.onInputChange} errors={errors.secondary_link_url} value={values.secondary_link_url} />
					    </div>
					    <div className="field-group">
					    	<DatePicker type="text" name="publish_start" label="Publish Start Date" onInputChange={this.props.onInputChange} errors={errors.publish_start} value={values.publish_start} showTimeSelect />
					    	<DatePicker type="text" name="publish_end" label="Publish End Date" onInputChange={this.props.onInputChange} errors={errors.publish_end} value={values.publish_end} showTimeSelect />
					    </div>
					</fieldset>
					<fieldset>
						<p className="form-title">Dates</p>
					    <EventDatesField name="dates" onChange={this.props.onInputChange} rows={values.dates} errors={errors.dates} />
					</fieldset>
				</form>
				{ 
					this.props.preview ? 
					<div className="page-preview-wrapper">
						<Event pageImage={this.props.singleEventImage} featuredImage={this.state.featuredimage} title={values.title} content={values.content} dates={values.dates} secondaryImage={this.state.secondaryimage} linkText={values.link_text} linkURL={values.link_url} secondaryLinkText={values.secondary_link_text} secondaryLinkURL={values.secondary_link_url} />
						<button className="button close-preview-button" onClick={this.props.togglePreview}>Close Preview</button>
					</div>
					: ''
				}
			</div>
		)
	}
}

export default EventForm;