import React, { Component } from 'react'
import logo from 'admin/assets/images/logo.png'
import { Route, Link } from 'react-router-dom'
import LandingContainer from '../containers/LandingContainer'
import AuditListingContainer from 'admin/modules/Audit/containers/AuditListingContainer'
import UserListingContainer from 'admin/modules/User/containers/UserListingContainer'
import UserEditFormContainer from 'admin/modules/User/containers/UserEditFormContainer'
import PageListingContainer from 'admin/modules/Page/containers/PageListingContainer'
import PageEditFormContainer from 'admin/modules/Page/containers/PageEditFormContainer'
import MenuListingContainer from 'admin/modules/Menu/containers/MenuListingContainer'
import MenuEditFormContainer from 'admin/modules/Menu/containers/MenuEditFormContainer'
import FormListingContainer from 'admin/modules/Form/containers/FormListingContainer'
import FormEditFormContainer from 'admin/modules/Form/containers/FormEditFormContainer'
import FormSubmission from 'admin/modules/Form/components/FormSubmission'
import SettingsFormContainer from 'admin/modules/Settings/containers/SettingsFormContainer'

import EventListingContainer from 'admin/modules/Event/containers/EventListingContainer'
import EventEditFormContainer from 'admin/modules/Event/containers/EventEditFormContainer'
import PostListingContainer from 'admin/modules/Post/containers/PostListingContainer'
import PostEditFormContainer from 'admin/modules/Post/containers/PostEditFormContainer'

import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import 'admin/assets/scss/admin.scss';

class Dashboard extends Component {
	render() {
		return (
			<div className="admin dashboard">
				<div className="dashboard-header">
					<div className="dashboard-header-inner">
						<div className="dashboard-header-left">
							<div className="logo">
								<a href="/dashboard"><img src={logo} alt="WJ CMS" /></a>
							</div>
							<nav className="dashboard-menu">
								<div className="menu-parent">
									<Link to="/admin/dashboard">Home</Link>
								</div>
								{this.props.showPages ?
								<div className="menu-parent">
									<span>Pages</span>
									<div className="sub-menu">
										<Link to="/admin/dashboard/pages">All Pages</Link>
										<Link to="/admin/dashboard/page/editor">Add New Page</Link>
									</div>
								</div> : '' }
								{this.props.showPosts ?
								<div className="menu-parent">
									<span>Posts</span>
									<div className="sub-menu">
										<Link to="/admin/dashboard/posts">All Posts</Link>
										<Link to="/admin/dashboard/post/editor">Add New Post</Link>
									</div>
								</div> : '' }
								{this.props.showEvents ?
								<div className="menu-parent">
									<span>Events</span>
									<div className="sub-menu">
										<Link to="/admin/dashboard/events">All Events</Link>
										<Link to="/admin/dashboard/event/editor">Add New Event</Link>
									</div>
								</div> : '' }
								{this.props.showMenus ?
								<div className="menu-parent">
									<span>Admin</span>
									<div className="sub-menu">
										<Link to="/admin/dashboard/menus">All Menus</Link>
										<Link to="/admin/dashboard/menu/editor">Add New Menu</Link>
										<Link to="/admin/dashboard/forms">All Forms</Link>
										<Link to="/admin/dashboard/form/editor">Add New Form</Link>
										<Link to="/admin/dashboard/activity-log">Activity Log</Link>
										<Link to="/admin/dashboard/users">All Users</Link>
										<Link to="/admin/dashboard/user/editor">Add New User</Link>
										<Link to="/admin/dashboard/settings">Site Settings</Link>
									</div>
								</div> : '' }
							</nav>
						</div>
						<div className="dashboard-header-right">
							<nav className="right-menu">
								<Link to={ "/admin/dashboard/user/editor/" + this.props.user.id }>{this.props.user.firstname} {this.props.user.lastname}</Link>
								<button onClick={this.props.onLogoutClick}><span>Logout</span> <FontAwesomeIcon icon={faSignOutAlt} size="lg" /></button>
							</nav>
						</div>
					</div>
				</div>
				<div className="dashboard-main">
					<Route exact path="/admin/dashboard" component={LandingContainer} />
					<Route path="/admin/dashboard/activity-log" component={AuditListingContainer} />
					<Route path="/admin/dashboard/users" component={UserListingContainer} />
					<Route path="/admin/dashboard/user/editor/:id?" component={UserEditFormContainer} />
					<Route path="/admin/dashboard/pages" component={PageListingContainer} />
					<Route path="/admin/dashboard/page/editor/:id?" component={PageEditFormContainer} />
					<Route path="/admin/dashboard/posts" component={PostListingContainer} />
					<Route path="/admin/dashboard/post/editor/:id?" component={PostEditFormContainer} />
					<Route path="/admin/dashboard/events" component={EventListingContainer} />
					<Route path="/admin/dashboard/event/editor/:id?" component={EventEditFormContainer} />
					<Route path="/admin/dashboard/menus" component={MenuListingContainer} />
					<Route path="/admin/dashboard/menu/editor/:id?" component={MenuEditFormContainer} />
					<Route path="/admin/dashboard/forms" component={FormListingContainer} />
					<Route path="/admin/dashboard/form/editor/:id?" component={FormEditFormContainer} />
					<Route path="/admin/dashboard/form/submission/:id?" component={FormSubmission} />
					<Route path="/admin/dashboard/settings" component={SettingsFormContainer} />
				</div>
				<div className="mobile-menu">
					<Link to="/admin/dashboard">Home</Link>
					<Link to="/admin/dashboard/pages">Pages</Link>
					<Link to="/admin/dashboard/menus">Menus</Link>
					<Link to="/admin/dashboard/activity-log">Activity Log</Link>
					<Link to="/admin/dashboard/users">Users</Link>
				</div>
			</div>
		)
	}
}

export default Dashboard;
