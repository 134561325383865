import React, { Component } from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'

class FiftyFifty extends Component {
	render() {
		let { items } = this.props.content;

		return (
			<div className="block fifty-fifty-block">
				<div className="block-inner">
					{ items.map((item, i) => {
						let { date, time, title, winning_number, amount, status } = item;

						return (
							<div className="fifty-fifty-item">
								<div className="fifty-fifty-left">
									{ date !== '' ? <span class="item-date">{ moment(date).format('MMMM D, YYYY') }</span> : ''}
									{ time !== '' ? <span class="item-time">{ time }</span> : ''}
									{ title !== '' ? <span class="item-title">{ title }</span> : ''}
								</div>
								<div className="fifty-fifty-right">
									<div className="fifty-fifty-winning">
										{ winning_number !== '' ? <span class="item-winning-number">{ winning_number }</span> : ''}
										{ amount !== '' ? <span class="item-amount">{ amount }</span> : ''}
									</div>
									{ status !== '' ? <span class={"item-status " + status}>{ status }</span> : ''}
								</div>
							</div>
						)
					})}
				</div>
			</div>
		)
	}
}

export default withRouter(FiftyFifty)