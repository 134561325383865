import React, { Component } from 'react'
import moment from 'moment'
import { getActiveEventDates } from 'admin/modules/Event/api'
import EventBucket from 'frontend/modules/Event/components/EventBucket'

class UpcomingEvents extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
	    	events: []
	    }
	}

	async getEvents() {
	    const fetchedEvents = await getActiveEventDates({
	    	'afterDate': moment().format('YYYY-MM-DD'),
	    	'limit': 4,
	    	'perPage': 0
	    });

	    let eventData = fetchedEvents.data.data

	    this.setState({
	    	events: eventData
	    });
	}

	componentDidMount() {
		this.getEvents();
	}

	render() {
		const { title, link_text, link_url } = this.props.content;

		return (
			<div className="block upcoming-events">
				<div className="block-header">
					{ title !== '' ? <h2 class="block-title">{ title }</h2> : '' }
					{ link_url !== '' ? <a class="block-link" href={ link_url }>{ link_text }</a> : '' }
				</div>
				<div className="event-list">
					{this.state.events.map((eventDate, i) => {
						return <EventBucket event={eventDate} />
					})}
				</div>
			</div>
		)
	}
}

export default UpcomingEvents