import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchForms, setFormsOrder, setFormsPage, deleteForm, setFormsSearch } from '../actions'
import { getForms, getFormsPage, getFormsPerPage, getFormsOrderBy, getFormsOrderDir, getFormsTotalPages, getFormsFetching, getFormsStatus, getFormDeleteSuccess, getFormsSearch } from '../selectors'
import Listing from 'admin/components/Listing'
import Delete from 'admin/components/Delete'
import Input from 'core/components/form/Input'

class FormListingContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			search: ''
		}

		this.onSearchChange = this.onSearchChange.bind(this)
	}

	onSearchChange(name, value) {
		this.setState({
			search: value
		})
	}

	render() {
		let columns = [
			{
				id: 'name',
				display: 'Name',
				value: item => {
					return <Link to={"/admin/dashboard/form/editor/" + item.id }>{item.name}</Link>
				}
			},
			{
				id: 'actions',
				display: '',
				value: item => {
					return <Delete onDelete={() => this.props.delete(item.id)} />
				}
			}
		];
		return (
			<div className="listing-wrapper">
				<div className="tab-nav">
					<span className="tab is-active">All Forms</span>
				</div>
				<div className="listing-actions">
					<Link className="button" to="/admin/dashboard/form/editor">Add New Form</Link>
					<div className="search-list">
						<Input type="search" name="search" label="Search" onInputChange={this.onSearchChange} />
						<button className="button" onClick={() => this.props.setSearch(this.state.search)}>Search</button>
					</div>
				</div>
				<div className="main-content">
					<Listing 
						columns={columns}
						{...this.props}
					/>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state = {}) => {
    return {
    	items: getForms(state),
    	page: getFormsPage(state),
    	perPage: getFormsPerPage(state),
    	orderBy: getFormsOrderBy(state),
    	orderDir: getFormsOrderDir(state),
    	totalPages: getFormsTotalPages(state),
    	isLoading: getFormsFetching(state),
    	status: getFormsStatus(state),
    	deleteSuccess: getFormDeleteSuccess(state),
    	search: getFormsSearch(state)
    }
};

const mapDispatchToProps = dispatch => {
	return {
		loadItems: data => {
			dispatch(fetchForms(data))
		},

		setOrderBy: (orderBy, orderDir) => {
			dispatch(setFormsOrder(orderBy, orderDir))
		},

		setPage: page => {
			dispatch(setFormsPage(page))
		},

		setSearch: search => {
			dispatch(setFormsSearch(search))
		},

		delete: id => {
			dispatch(deleteForm(id))
		}
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(FormListingContainer)