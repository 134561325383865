export const getMenusErrors = (state) => state.menus.errors
export const getMenusFetching = (state) => state.menus.isFetching
export const getCurrentMenu = (state) => state.menus.currentMenu
export const getMenuSaveSuccess = (state) => state.menus.saveSuccess
export const getMenuDeleteSuccess = (state) => state.menus.deleteSuccess

export const getMenus = (state) => state.menus.menus
export const getMenusPage = (state) => state.menus.page
export const getMenusPerPage = (state) => state.menus.perPage
export const getMenusOrderBy = (state) => state.menus.orderBy
export const getMenusOrderDir = (state) => state.menus.orderDir
export const getMenusTotalPages = (state) => state.menus.totalPages
export const getMenusStatus = (state) => state.menus.status
export const getMenusSearch = (state) => state.menus.search