import React, { Component } from 'react';
import Field from './Field'
import { Editor } from '@tinymce/tinymce-react';

class WYSIWYG extends Component {
	render() {
		// const { label, errors, removeFocus, ...rest} = this.props

		return (
			<Field {...this.props}>
				<Editor
						apiKey="p1vhmuhgydawkbn17brbnw1mn0ctfyenz61ir0h1i6mz4217"
				         initialValue={this.props.value}
				         init={{
				           height: 250,
				           menubar: false,
				           plugins: [
				             'advlist autolink lists link image charmap print preview anchor',
				             'searchreplace visualblocks code fullscreen',
				             'insertdatetime media table paste code help wordcount'
				           ],
				           toolbar:
				             'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
				         }}
				         onEditorChange={(content, editor) => this.props.onInputChange(this.props.name, content)}
				       />
			</Field>
		)
	}
}

export default WYSIWYG;