import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import WYSIWYG from 'core/components/form/WYSIWYG'
import ImageUpload from 'core/components/form/ImageUpload'

export const fieldDefaults = {
	'image': {},
	'title': '',
	'left_column_content': '',
	'right_column_content': '',
}

export class TwoColumnOnImage extends Component {
	render() {
		return (
			<div className="block">
				<p className="block-title">Two Column on Image</p>
				<div className="block-fields">
					<ImageUpload name="image" onInputChange={(name, value) => this.props.onChange(name, value)} value={this.props.content.image._id} />
					<Input type="text" name="title" label="Left Column Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.left_column_title} />
					<WYSIWYG name="left_column_content" label="Left Column Content" onInputChange={this.props.onChange} value={this.props.content.left_column_content} />
					<WYSIWYG name="right_column_content" label="Right Column Content" onInputChange={this.props.onChange} value={this.props.content.right_column_content} />
				</div>
			</div>
		);
	}
}