import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {withRouter} from 'react-router-dom';
import Event from '../components/Event'
import { getEventBySlug, getActiveEventDates } from 'admin/modules/Event/api'
import { getSetting } from 'admin/modules/Settings/selectors'

class EventContainer extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
	    	featuredImage: {},
	    	title: '',
	    	content: '',
	    	created_at: '',
	    	dates: [],
	    	secondaryImage: {},
	    	linkText: '',
	    	linkURL: '',
	    	secondaryLinkText: '',
	    	secondaryLinkURL: '',
	    	otherEvents: [],
	    	otherEventsLoading: false
	    }
	}

	async getEvent(slug) {
		if(slug === undefined)
			return false;

	    await getEventBySlug(slug)
	    	.then(post => {
	    		let postData = post.data.data

	    		document.title = 'SaskTel Centre - ' + postData.title;

	    		this.setState({
	    			featuredImage: postData.featuredimage,
	    			title: postData.title,
	    			content: postData.content,
	    			dates: postData.dates,
	    			secondaryImage: postData.secondaryimage,
	    			linkText: postData.link_text,
	    			linkURL: postData.link_url,
	    			secondaryLinkText: postData.secondary_link_text,
	    			secondaryLinkURL: postData.secondary_link_url,
	    			created_at: postData.createdAt
	    		});
	    	})
	    	.catch(error => {
		    	if(error.response.status === 404)
		    		this.props.history.push('/404');
		    		return;
	    	}) 
	}

	async loadOtherEvents() {
		this.setState({
			otherEventsLoading: true
		})

		let data = {
			afterDate: moment().format('YYYY-MM-DD'),
			page: 1,
			perPage: 4,
			orderBy: 'start',
			orderDir: 'asc'
		}

	    await getActiveEventDates(data)
	    	.then(response => {
	    		this.setState({
					otherEventsLoading: false,
					otherEvents: response.data.data,
				})
	    	})
	    	.catch(error => {
		    	this.setState({
					otherEventsLoading: false
				})
	    	}) 
	}

	componentDidMount() {
		let path = this.props.match.params.slug;
		if(path !== undefined) {
			path = path.replace(/(^\/)|(\/$)/g, "")
			this.getEvent(path);
			this.loadOtherEvents();
		}
	}

	render() {
		const { featuredImage, title, content, dates, secondaryImage, linkText, linkURL, secondaryLinkText, secondaryLinkURL, created_at, otherEvents, otherEventsLoading } = this.state

		return (
			<Event pageImage={this.props.singleEventImage} featuredImage={featuredImage} title={title} content={content} dates={dates} secondaryImage={secondaryImage} linkText={linkText} linkURL={linkURL} secondaryLinkText={secondaryLinkText} secondaryLinkURL={secondaryLinkURL} createdDate={created_at} otherEvents={otherEvents} otherEventsLoading={otherEventsLoading} />
		)
	}
}

function mapStateToProps(state) {
  return {
    singleEventImage: JSON.parse(getSetting(state, 'single_event_image')),
  }
}

export default connect(mapStateToProps)(withRouter(EventContainer));