import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class ImageWithContentBuckets extends Component {
	render() {
		let { buckets } = this.props.content;

		return (
			<div className="block image-content-buckets-block">
				{ buckets.map((bucket, i) => {
					let { image, title, content } = bucket;

					return (
						<div className="bucket">
							{ image !== "" && image !== undefined ? <img src={ image.thumbnailUrl + '?w=525&h=325&fit=crop'} /> : ''}
							<div className="bucket-main">
								{ title !== '' ? <p class="bucket-title">{ title }</p> : ''}
								{ content !== '' ? <p class="bucket-content">{ content }</p> : ''}
							</div>
						</div>
					)
				})}
			</div>
		)
	}
}

export default withRouter(ImageWithContentBuckets)