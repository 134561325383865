import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import MultiRowWrapper from 'core/components/form/MultiRowWrapper'
import Textarea from 'core/components/form/Textarea'
import Select from 'core/components/form/Select'
import ImageUpload from 'core/components/form/ImageUpload'
import WYSIWYG from 'core/components/form/WYSIWYG'

class Slide extends Component {
	render() {
		return (
			<div className="repeater">
				
				{this.props.rows.map((item, i) => {
					return <div className="repeater-section" key={i}>
						<ImageUpload name="image" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={item.image._id} />
						<button type="button" className="remove" onClick={() => this.props.removeRow(i)}></button>
					</div>
				})}
				<button type="button" className="add" onClick={() => this.props.addRow()}>
					Add Slide <span>+</span>
				</button>
			</div>
		)
	}
}

const SlideField = MultiRowWrapper(Slide, {
        image: {}
    });

export const fieldDefaults = {
	'title': '',
	'content': '',
	'slideshow_side': 'left',
	'slides': []
}

export class SlideshowWithContent extends Component {
	render() {
		return (
			<div className="block">
				<p className="block-title">Slideshow With Content</p>
				<div className="block-fields">
					<Select name="slideshow_side" label="Slideshow Side" onInputChange={this.props.onChange} value={this.props.content.slideshow_side}>
						<option value="left">Left</option>
						<option value="right">Right</option>
					</Select>
					<Input type="text" name="title" label="Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.title} />
					<WYSIWYG name="content" label="Content" onInputChange={this.props.onChange} value={this.props.content.content} />
					<SlideField name="slides" onChange={this.props.onChange} rows={this.props.content.slides} />
				</div>
			</div>
		);
	}
}