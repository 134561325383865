import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import Authenticated from './core/components/routes/Authenticated';
import FrontEndContainer from './frontend/containers/FrontEndContainer';
import AdminLoginContainer from './admin/modules/Auth/components/LoginPage';
import DashboardContainer from './admin/modules/Dashboard/containers/DashboardContainer';
// import AdminPasswordResetEmailContainer from './modules/Admin/Auth/components/PasswordResetEmailPage';
// import AdminPasswordResetContainer from './modules/Admin/Auth/containers/PasswordResetContainer';

import ErrorModal from './core/modules/Error/ErrorModal';

import { clearError } from './core/modules/Error/actions';
import { logoutUser } from './admin/modules/Auth/actions'

import { ConnectedRouter } from 'connected-react-router'
import { history } from './configureStore'

class App extends Component {
  render() {
    return (
      <ConnectedRouter history={history}>
        <div className="App">
          {(this.props.errorMessage ? <ErrorModal message={this.props.errorMessage} onClose={this.props.clearError} /> : '' )}
          <Switch>
              <Route exact path="/admin" render={() => <Redirect to="/login"/>}/>
              <Route exact path="/logout" 
                render={
                  () => {
                    this.props.logout();
                    return <Redirect to="/"/>
                  }
                } />
              <Authenticated path="/admin/dashboard" component={DashboardContainer} isAuthenticated={this.props.isAuthenticated} loginComponent={AdminLoginContainer} />
              <Route component={FrontEndContainer} />
          </Switch>
        </div>
      </ConnectedRouter>
    );
  }
}

App.propTypes = {
  isAuthenticated: PropTypes.bool,
  errorMessage: PropTypes.string
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.errorMessage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearError: () => {
      dispatch(clearError())
    },

    logout: creds => {
        dispatch(logoutUser(creds))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

