import React, { Component } from 'react'

class Dropdown extends Component {
	constructor(props) {
		super(props)

		this.state = {
			open: false
		}
	}

	render() {

		return (
			<div className={"dropdown" + (this.state.open ? " open" : '')}>
				{ this.props.items.map( (item, i) => {
					if(i == this.props.selected)
						return <div className="dropdown-selected" onClick={() => this.setState({ open: !this.state.open })}>{ item }</div>
					return false;
				})}
				{ this.state.open ? 
					<div className="dropdown-items">
						{ this.props.items.map( (item, i) => {
							return <div className="dropdown-item" onClick={() => this.props.onChange(i)}>{ item }</div>
						})}
					</div>
				: '' }
			</div>
		)
	}
}

export default Dropdown