import React, {Component} from 'react'
import moment from 'moment'

class CalendarEvent extends Component {
	render() {
		return (
			<div className="event">
				<div className="event-title">{ moment(this.props.event.start).format('h:mma') } | {this.props.event.event.title}</div>
			</div>
		)
	}
}

export default CalendarEvent