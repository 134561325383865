import React, { Component } from 'react'
import Edit from 'admin/components/EditForm'
import SettingsForm from '../components/SettingsForm'
import FormWrapper from 'core/components/form/FormWrapper'
import { saveSettings, getSettings } from '../api'

const fields = [
	'site_name',
	{
		name: 'logo',
		default: {},
		parseExisting: value => {
			return JSON.parse(value);
		}
	},
	'copyright',
	'top_link_text',
	'top_link_url',
	{
		name: 'suitelife_logo',
		default: {},
		parseExisting: value => {
			return JSON.parse(value);
		}
	},
	'suitelife_url',
	'sponsors_title',
	{
		name: 'sponsors',
		default: [],
		parseExisting: value => {
			return JSON.parse(value);
		}
	},
	{
		name: 'single_event_image',
		default: {},
		parseExisting: value => {
			return JSON.parse(value);
		}
	},
	{
		name: 'error_image',
		default: {},
		parseExisting: value => {
			return JSON.parse(value);
		}
	},
	'error_title',
	'error_subtitle',
	'error_link_text',
	'error_link_url',

]
const EditForm = FormWrapper(Edit, fields)

class SettingsEditFormContainer extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
			isLoading: false,
			apiErrors: [],
			settings: undefined,
			saveSuccess: false
		}

		this.save = this.save.bind(this);
		this.getSettings = this.getSettings.bind(this);
		this.validate = this.validate.bind(this);
	}

	componentWillMount() {
		this.getSettings();
	}

	// componentWillReceiveProps(nextProps) {
	// 	if(this.props.match.params.id !== nextProps.match.params.id){
	// 		if(nextProps.match.params.id !== undefined)
	// 			this.getSettings(nextProps.match.params.id);
	// 	}
	// }

	validate(name, label, value) {

		let errors = [];
		// if(name === 'name' || name ==='start') {
		// 	if(value.length === 0) {
		// 		errors.push(label + " is required.");
		// 	}
		// }

		return errors;
	}

	handleErrors(error) {
		if(error.response && error.response.status === 422) {
	    	this.setState({
				isLoading: false,
				saveSuccess: false,
				apiErrors: error.response.data.errors
			})
		} else {
	    	this.setState({
				isLoading: false,
				saveSuccess: false
			})
	    }
	}

	handleSuccess(response) {
		this.setState({
			isLoading: false,
			saveSuccess: true,
			settings: response.data.data
		})
	}

	async save(data) {
		this.setState({
			isLoading: true,
			saveSuccess: false
		})

	    await saveSettings(data)
	    	.then(response => {
	    		this.handleSuccess(response);
	    	})
	    	.catch(error => {
	    		this.handleErrors(error);
	    	})
	}

	async getSettings() {
		this.setState({
			isLoading: true
		})

	    await getSettings()
	    	.then(response => {
	    		this.setState({
					isLoading: false,
					settings: response.data
				})
	    	})
	    	.catch(error => {
		    	this.setState({
					isLoading: false
				})
	    	})
	}

	render() {
		return (
			<div>
				<EditForm
					getExisting={this.getSettings}
					validate={this.validate} 
					name="Settings"
					editValues={this.state.settings}
					backLink="/admin/dashboard"
					isLoading={this.state.isLoading}
					apiErrors={this.state.apiErrors}
					saveSuccess={this.state.saveSuccess}
					onSubmit={this.save}
					noStatus={true}
				>
					<SettingsForm />
				</EditForm>
			</div>
		)
	}
}

export default SettingsEditFormContainer