import React, { Component } from 'react'
import moment from 'moment'
import PostList from './PostList'
import { default as Footer } from 'frontend/containers/layout/FooterContainer';

class Post extends Component {
	render() {
		return (
			<div className="post-page third-color">
				{this.props.featuredImage !== null ? <div className="featured-image" style={{ backgroundImage: 'url(' + this.props.featuredImage.thumbnailUrl + '?w=1920)'}}></div> : '' }
				<div class="curved">
					<div class="curved-inner">
						<div className="post-page-inner">
							<a className="large-arrow-link back" href="/news">Back to News & Updates</a>
							<div className="post-main">
								<div className="post-main-inner">
									<div className="post-date">{ moment(this.props.createdDate).format('MMMM D, YYYY | h:mmA') }</div>
									<h2 className="post-title">{ this.props.title }</h2>
									<div className="post-excerpt" dangerouslySetInnerHTML={{__html: this.props.content}}></div>
								</div>
							</div>
							{ this.props.otherPosts ? <PostList items={this.props.otherPosts} /> : '' }
						</div>
						<Footer />
					</div>
				</div>
				
			</div>
		)
	}
}

export default Post