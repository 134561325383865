export const getPagesErrors = (state) => state.pages.errors
export const getPagesFetching = (state) => state.pages.isFetching
export const getCurrentPage = (state) => state.pages.currentPage
export const getPageSaveSuccess = (state) => state.pages.saveSuccess
export const getPageDeleteSuccess = (state) => state.pages.deleteSuccess

export const getPages = (state) => state.pages.pages
export const getPagesPage = (state) => state.pages.page
export const getPagesPerPage = (state) => state.pages.perPage
export const getPagesOrderBy = (state) => state.pages.orderBy
export const getPagesOrderDir = (state) => state.pages.orderDir
export const getPagesTotalPages = (state) => state.pages.totalPages
export const getPagesStatus = (state) => state.pages.status
export const getPagesSearch = (state) => state.pages.search