import React, {Component} from 'react'
import { connect } from 'react-redux'
import { fetchAudits, setAuditsOrder, setAuditsPage, setAuditsSearch } from '../actions'
import { getAudits, getAuditsPage, getAuditsPerPage, getAuditsOrderBy, getAuditsOrderDir, getAuditsTotalPages, getAuditsFetching, getAuditsSearch } from '../selectors'
import Listing from 'admin/components/Listing'
import Input from 'core/components/form/Input'
import moment from 'moment'

class AuditListingContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			search: ''
		}

		this.onSearchChange = this.onSearchChange.bind(this)
	}

	onSearchChange(name, value) {
		this.setState({
			search: value
		})
	}

	render() {
		let columns = [
			{
				id: 'message',
				display: 'Title'
			},
			{
				id: 'user',
				display: 'Author',
				value: item => {
					if(item.user !== null)
						return item.user.firstname + " " + item.user.lastname
					return ''
				}
			},
			{
				id: 'created_at',
				display: 'Date',
				value: item => {
					return moment.utc(item.created_at.date).local().format('MMMM D, YYYY - hh:mm a')
				}
			}
		];
		return (
			<div className="listing-wrapper">
				<div className="tab-nav">
					<span className="tab is-active">Activity Log</span>
				</div>
				<div className="listing-actions">
					<div className="search-list">
						<Input type="search" name="search" label="Search" onInputChange={this.onSearchChange} />
						<button className="button" onClick={() => this.props.setSearch(this.state.search)}>Search</button>
					</div>
				</div>
				<div className="main-content">
					<Listing 
						columns={columns}
						{...this.props}
					/>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state = {}) => {
    return {
    	items: getAudits(state),
    	page: getAuditsPage(state),
    	perPage: getAuditsPerPage(state),
    	orderBy: getAuditsOrderBy(state),
    	orderDir: getAuditsOrderDir(state),
    	totalPages: getAuditsTotalPages(state),
    	isLoading: getAuditsFetching(state),
    	search: getAuditsSearch(state)
    }
};

const mapDispatchToProps = dispatch => {
	return {
		loadItems: data => {
			dispatch(fetchAudits(data))
		},

		setOrderBy: (orderBy, orderDir) => {
			dispatch(setAuditsOrder(orderBy, orderDir))
		},

		setPage: page => {
			dispatch(setAuditsPage(page))
		},

		setSearch: search => {
			dispatch(setAuditsSearch(search))
		}
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(AuditListingContainer)