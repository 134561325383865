import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class SquareImageBuckets extends Component {
	render() {
		let { buckets } = this.props.content;

		return (
			<div className="block square-image-buckets">
				{ buckets.map((bucket, i) => {
					let { image, title, url } = bucket;

					return (
						<div className="bucket" style={{ backgroundImage: 'url(' + image.thumbnailUrl + '?w=500&h=500&fit=crop)'}} onClick={() => window.location = url} >
							{ title !== '' ? <p class="bucket-title">{ title }</p> : ''}
						</div>
					)
				})}
			</div>
		)
	}
}

export default withRouter(SquareImageBuckets)