import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import logo from 'admin/assets/images/logo.png'
import LoginContainer from '../containers/LoginContainer'
import 'admin/assets/scss/admin.scss';

class LoginPage extends Component {
	render() {
		return (
			<div className="admin login-container">
				<div className="login">
					<img src={logo} alt="WJ CMS" className="logo" />
					<LoginContainer />

					<nav className="login-nav menu">
						<Link to="/admin/forgot-password">Lost your password?</Link>
						<Link to="/">Back to WJ CMS</Link>
					</nav>
				</div>
				
			</div>
		)
	}
}

export default LoginPage;