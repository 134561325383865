import React, { Component } from 'react'
import AccordionItem from './block-parts/AccordionItem'

class Directors extends Component {
	render() {
		let { title, buckets } = this.props.content

		return (
			<div className="block directors-block ">
				<div className="block-inner">
					{title !== "" ? <h2 className="block-title">{title}</h2> : ''}
					<ul className="directors">
						{buckets .map((bucket, i) => {
							const { image, title, subtitle } = bucket;
							return (
								<div className="director">
									{image !== "" && image !== undefined ? <img className="director-image" src={image.thumbnailUrl + '?w=250&h=250&fit=crop'} /> : ''}
									<div className="director-main">
										{title !== "" ? <h3 className="director-title">{title}</h3> : ''}
										{subtitle !== "" ? <p className="director-subtitle">{subtitle}</p> : ''}
									</div>
								</div>
							);
						})}
					</ul>
				</div>
			</div>
		)
	}
}

export default Directors