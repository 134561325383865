// import React, {Component} from 'react'
import { connect } from 'react-redux'
import Header from 'frontend/components/layout/Header'
import { getSetting } from 'admin/modules/Settings/selectors'

function mapStateToProps(state) {
	
  return {
  	suiteLifeLogo: JSON.parse(getSetting(state, 'suitelife_logo')),
    suiteLifeURL: getSetting(state, 'suitelife_url'),
    topLinkText: getSetting(state, 'top_link_text'),
    topLinkURL: getSetting(state, 'top_link_url'),
    logo: JSON.parse(getSetting(state, 'logo'))
  }
}


export default connect(mapStateToProps)(Header)