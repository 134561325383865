import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import Select from 'core/components/form/Select'

export const fieldDefaults = {
	'title': '',
	'link_text': '',
	'link_url': '',
	'link_style': 'button'
}

export class CTA extends Component {
	render() {
		return (
			<div className="block">
				<p className="block-title">CTA</p>
				<div className="block-fields">
					<Input type="text" name="title" label="Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.title} />
					<div className="field-group">
						<Input type="text" name="link_text" label="Link Text" onInputChange={this.props.onChange} value={this.props.content.link_text} />
						<Input type="text" name="link_url" label="Link URL" onInputChange={this.props.onChange} value={this.props.content.link_url} />
						<Select name="link_style" label="Link Style" onInputChange={this.props.onChange} value={this.props.content.link_style}>
							<option value="button">Button</option>
							<option value="link">Link</option>
						</Select>
					</div>
				</div>
			</div>
		);
	}
}