import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import MultiRowWrapper from 'core/components/form/MultiRowWrapper'
import Textarea from 'core/components/form/Textarea'
import Select from 'core/components/form/Select'
import WYSIWYG from 'core/components/form/WYSIWYG'
import ImageUpload from 'core/components/form/ImageUpload'

class Bucket extends Component {
	render() {
		return (
			<div className="repeater">
				
				{this.props.rows.map((bucket, i) => {
					return <div className="repeater-section" key={i}>
						<ImageUpload name="image" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={bucket.image._id} />
						<Input type="text" name="title" label="Title" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={bucket.title} />
						<Input type="text" name="subtitle" label="Subtitle" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={bucket.subtitle} />
						<button type="button" className="remove" onClick={() => this.props.removeRow(i)}></button>
					</div>
				})}
				<button type="button" className="add" onClick={() => this.props.addRow()}>
					Add Director <span>+</span>
				</button>
			</div>
		)
	}
}

const BucketField = MultiRowWrapper(Bucket, {
        image: {},
        title: '',
        subtitle: ''
    });

export const fieldDefaults = {
	'title': '',
	'buckets': []
}

export class Directors extends Component {
	render() {
		return (
			<div className="block">
				<p className="block-title">Directors</p>
				<div className="block-fields">
					<Input type="text" name="title" label="Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.title} />
					<BucketField name="buckets" onChange={this.props.onChange} rows={this.props.content.buckets} />
				</div>
			</div>
		);
	}
}