import React, { Component } from 'react';

import { Accordion, fieldDefaults as AccordionDefaults } from './blocks/Accordion'
import { Contact, fieldDefaults as ContactDefaults } from './blocks/Contact'
import { ContentOnImage, fieldDefaults as ContentOnImageDefaults } from './blocks/ContentOnImage'
import { ContentWithBuckets, fieldDefaults as ContentWithBucketsDefaults } from './blocks/ContentWithBuckets'
import { CTA, fieldDefaults as CTADefaults } from './blocks/CTA'
import { Directors, fieldDefaults as DirectorsDefaults } from './blocks/Directors'
import { Events, fieldDefaults as EventsDefaults } from './blocks/Events'
import { FeatureBuckets, fieldDefaults as FeatureBucketsDefaults } from './blocks/FeatureBuckets'
import { FeaturedEvents, fieldDefaults as FeaturedEventsDefaults } from './blocks/FeaturedEvents'
import { FiftyFifty, fieldDefaults as FiftyFiftyDefaults } from './blocks/FiftyFifty'
import { ImageSelector, fieldDefaults as ImageSelectorDefaults } from './blocks/ImageSelector'
import { ImageText, fieldDefaults as ImageTextDefaults } from './blocks/ImageText'
import { ImageWithContentBuckets, fieldDefaults as ImageWithContentBucketsDefaults } from './blocks/ImageWithContentBuckets'
import { InstagramFeed, fieldDefaults as InstagramFeedDefaults } from './blocks/InstagramFeed'
import { Map, fieldDefaults as MapDefaults } from './blocks/Map'
import { News, fieldDefaults as NewsDefaults } from './blocks/News'
import { NewsletterSignup, fieldDefaults as NewsletterSignupDefaults } from './blocks/NewsletterSignup'
import { SlideshowWithContent, fieldDefaults as SlideshowWithContentDefaults } from './blocks/SlideshowWithContent'
import { SquareImageBuckets, fieldDefaults as SquareImageBucketsDefaults } from './blocks/SquareImageBuckets'
import { TwoColumnContent, fieldDefaults as TwoColumnContentDefaults } from './blocks/TwoColumnContent'
import { TwoColumnOnImage, fieldDefaults as TwoColumnOnImageDefaults } from './blocks/TwoColumnOnImage'
import { UpcomingEvents, fieldDefaults as UpcomingEventsDefaults } from './blocks/UpcomingEvents'

import Select from 'core/components/form/Select'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

class PageBlocks extends Component {
	components = {
		'accordion': {
			block: Accordion,
			default: AccordionDefaults
		},
		'contact': {
			block: Contact,
			default: ContactDefaults
		},
		'content-on-image': {
			block: ContentOnImage,
			default: ContentOnImageDefaults
		},
		'content-with-buckets': {
			block: ContentWithBuckets,
			default: ContentWithBucketsDefaults
		},
		'cta': {
			block: CTA,
			default: CTADefaults
		},
		'directors': {
			block: Directors,
			default: DirectorsDefaults
		},
		'events': {
			block: Events,
			default: EventsDefaults
		},
		'feature-buckets': {
			block: FeatureBuckets,
			default: FeatureBucketsDefaults
		},
		'featured-events': {
			block: FeaturedEvents,
			default: FeaturedEventsDefaults
		},
		'fifty-fifty': {
			block: FiftyFifty,
			default: FiftyFiftyDefaults
		},
		'image-selector': {
			block: ImageSelector,
			default: ImageSelectorDefaults
		},
		'image-text': {
			block: ImageText,
			default: ImageTextDefaults
		},
		'image-with-content-buckets': {
			block: ImageWithContentBuckets,
			default: ImageWithContentBucketsDefaults
		},
		'instagram-feed': {
			block: InstagramFeed,
			default: InstagramFeedDefaults
		},
		'map': {
			block: Map,
			default: MapDefaults
		},
		'news': {
			block: News,
			default: NewsDefaults
		},
		'newsletter-signup': {
			block: NewsletterSignup,
			default: NewsletterSignupDefaults
		},
		'slideshow-with-content': {
			block: SlideshowWithContent,
			default: SlideshowWithContentDefaults
		},
		'square-image-buckets': {
			block: SquareImageBuckets,
			default: SquareImageBucketsDefaults
		},
		'two-column-content': {
			block: TwoColumnContent,
			default: TwoColumnContentDefaults
		},
		'two-column-on-image': {
			block: TwoColumnOnImage,
			default: TwoColumnOnImageDefaults
		},
		'upcoming-events': {
			block: UpcomingEvents,
			default: UpcomingEventsDefaults
		},
		
	};

	constructor(props) {
	    super(props);

		this.state = {
			'type': ''
		}

		this.addBlock = this.addBlock.bind(this);
		this.changeBlockType = this.changeBlockType.bind(this);
		this.onBlockChange = this.onBlockChange.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	changeBlockType(value) {
		this.setState({
			type: value
		})
	}

	addBlock() {
		let block = this.components[this.state.type]
		// console.log(block.default)
		this.props.addRow({
        	type: this.state.type,
        	content: JSON.stringify(block.default)
    	})
	}

	onBlockChange(index, oldContent, name, value) {
		let newContent = JSON.parse(oldContent);
		newContent[name] = value;

		this.props.onRowChange(index, 'content', JSON.stringify(newContent));
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }

        this.props.reorder(
          result.source.index,
          result.destination.index
        );
     }

	render() {
		return (
			<div className="blocks">
				<DragDropContext onDragEnd={this.onDragEnd}>
        			<Droppable droppableId="droppable">
        				{(provided, snapshot) => (
        			            <div
        			              {...provided.droppableProps}
        			              ref={provided.innerRef}
        			              className={ (snapshot.isDraggingOver ? 'active-dragging ' : '') }
        			            >
									{this.props.rows.map((block, i) => {
										let BlockComponent = this.components[block.type].block;

										return (
											<Draggable key={block.type+i} draggableId={block.type+i} index={i}>
												{(provided, snapshot) => (
								                    <div
								                      ref={provided.innerRef}
								                      {...provided.draggableProps}
								                      {...provided.dragHandleProps}
								                      {...provided.draggableProps.style}
								                      className={ (snapshot.isDragging ? 'is-dragging ' : '') }
								                    >
														<div className="block-row">
															<BlockComponent onChange={(name, value) => this.onBlockChange(i, block.content, name, value)} content={JSON.parse(block.content)} />
															<button type="button" className="remove-block button" onClick={() => this.props.removeRow(i)}>Remove Block</button>
														</div>
													</div>
                  								)}
											</Draggable>
										);
									})}
								{provided.placeholder}
					        </div>
					    )}
					</Droppable>
				</DragDropContext>
				<div className="add-block">
					<Select name="type" label="Block Type" onInputChange={(name, value) => this.changeBlockType(value)}>
						<option value=""></option>
						<option value="fifty-fifty">50/50</option>
						<option value="accordion">Accordion</option>
						<option value="contact">Contact</option>
						<option value="content-on-image">Content on Image</option>
						<option value="content-with-buckets">Content With Buckets</option>
						<option value="cta">CTA</option>
						<option value="directors">Directors</option>
						<option value="events">Events</option>
						<option value="feature-buckets">Feature Buckets</option>
						<option value="featured-events">Featured Events</option>
						<option value="image-selector">Image Selector</option>
						<option value="image-text">Image Text</option>
						<option value="image-with-content-buckets">Image With Content Buckets</option>
						<option value="instagram-feed">Instagram Feed</option>
						<option value="map">Map</option>
						<option value="news">News</option>
						<option value="newsletter-signup">Newsletter Signup</option>
						<option value="slideshow-with-content">Slideshow With Content</option>
						<option value="square-image-buckets">Square Image Buckets</option>
						<option value="two-column-content">Two Column Content</option>
						<option value="two-column-on-image">Two Column On Image</option>
						<option value="upcoming-events">Upcoming Events</option>
					</Select>
					<button type="button" className="add button" onClick={this.addBlock}>
						Add Block <span>+</span>
					</button>
				</div>
			</div>
		)
	}
}

export default PageBlocks;