import React, { Component } from 'react'
import moment from 'moment'
import TinySlider from "tiny-slider-react";

class SlideshowWithContent extends Component {
	render() {
		const { title, content, slides, slideshow_side } = this.props.content;
		const settings = {
		  lazyload: true,
		  mouseDrag: true
		};
		return (
			<div className={"block slideshow-with-content-block " + slideshow_side}>
				<div className="block-inner">
					<TinySlider settings={settings}>
						{slides.map((slide, i) => {
							let { image } = slide;
							return (
								<div>
									<div className="slide">
										{image !== "" && image !== undefined ? <img className="slide-image" src={image.thumbnailUrl + '?w=950&h=550&fit=crop'} /> : ''}
									</div>
								</div>
							);
						})}
					</TinySlider>
				
					<div className="block-main">
						{title !== "" ? <h2 className="block-title">{title}</h2> : ''}
						{content !== "" ? <div className="block-content" dangerouslySetInnerHTML={{__html: content}}></div> : ''}
					</div>
				</div>
			</div>
		)
	}
}

export default SlideshowWithContent