import React, { Component } from 'react';
import MenuItems from './MenuItems'
import Input from 'core/components/form/Input'
import MultiRowWrapper from 'core/components/form/MultiRowWrapper'

const MenuItemsField = MultiRowWrapper(MenuItems, {
	title: '',
	class: '',
	url: '',
	target: '',
    items: [],
    'custom_fields': null
});

class MenuForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			reorder: false
		}
	}

	render() {
		const { values, errors } = this.props

		let theme = 'default';
		if(this.props.id == 1)
			theme = 'main-menu'

		return (
			<div className="main-content">
				<form className="login-form" onSubmit={this.props.onSubmit}>
					<fieldset>
						<div className="form-header">
							<p className="form-title">Menu</p>
							<button className="button" type="button" onClick={() => this.setState({ reorder: !this.state.reorder }) }>{ (this.state.reorder ? 'Edit' : 'Reorder') }</button>
						</div>
						<Input type="text" name="name" label="Name" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.name} errors={errors.name} />
						<Input type="text" name="class" label="Class" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.class} errors={errors.class} />
						<MenuItemsField name="items" onChange={this.props.onInputChange} rows={values.items} errors={errors.items} expanded={!this.state.reorder} theme={theme}/>
					</fieldset>
				</form>
			</div>
		)
	}
}

export default MenuForm;