import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'



class MenuItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			menuOpen: false
		}

		this.openSubNav = this.openSubNav.bind(this)
		this.closeSubNav = this.closeSubNav.bind(this)
		this.onHover = this.onHover.bind(this)
		this.onHoverOut = this.onHoverOut.bind(this)
	}

	onHover() {
		if(this.props.items.length > 0) {
			this.setState({
				menuOpen: true
			})
			this.props.onItemHover();
		}
	}

	onHoverOut() {
		this.setState({
			menuOpen: false
		})
		if(this.props.items.length > 0) {
			this.props.onItemHoverOut();
		}
	}


	openSubNav() {
		if(this.props.items.length > 0) {
			this.setState({
				menuOpen: true
			})
		} else {
			// this.props.history.push(this.props.url)
			window.location = this.props.url
			if(this.props.closeParent)
				this.props.closeParent();
			window.scrollTo(0, 0)
		}
	}

	closeSubNav() {
		this.setState({
			menuOpen: false
		})
		// if(this.props.items.length > 0) {
		// 	this.props.onItemHoverOut();
		// }
	}

	render() {

		let subNavTheme = '';
		if(this.props.customFields !== null && this.props.customFields !== undefined && this.props.customFields.theme !== undefined)
			subNavTheme = this.props.customFields.theme
		return (
			<div className={"menu-item " + (this.props.items.length > 0 ? 'has-children ' : '') + this.props.theme} onMouseEnter={this.onHover} onMouseLeave={this.onHoverOut}>
				<a target={this.props.target} className={this.props.class} onClick={this.openSubNav}>
					{this.props.theme == 'logos' ?
						<div className="menu-logo-wrapper"><img src={ this.props.customFields.logo.thumbnailUrl + '?w=180'} /></div>
					: ''}
					<span>{this.props.title}</span>
				</a>
				{ this.props.items.length > 0 ?
					<div className={"sub-nav " + subNavTheme + (this.state.menuOpen ? ' is-active' : '')}>
						<div className="sub-nav-inner-wrapper">
							<div className="sub-nav-inner">
								{this.props.theme == 'main-menu-theme' ?
									<div className="expanded-header">
										<button type="button" class="button back-button" onClick={this.closeSubNav}>Back</button>
										<p className="expanded-header-title">{ this.props.title }</p>
									</div>
								: ''}
								{this.props.theme == 'main-menu-theme' ?
									<a href={this.props.url} className="expanded-link">
										<span class="expanded-title">{ this.props.customFields.expanded_title }</span>
										<span class="expanded-subtitle">{ this.props.customFields.expanded_subtitle }</span>
									</a>
								: ''}
								<div className="subnav-items">
								{this.props.items.map((item, i) => {
									return (
										<RoutedMenuItem key={i} url={item.url} target={item.target} title={item.title} class={item.class} items={item.items} theme={subNavTheme} customFields={item.custom_fields} closeParent={this.closeSubNav}/>
									)
								})}

								</div>
							</div>
						</div>
					</div>
				: '' }
			</div>
		)
	}
}

let RoutedMenuItem = withRouter(MenuItem);

export default RoutedMenuItem