import React, { Component } from 'react'

class CTA extends Component {
	render() {
		const { title, link_url, link_text, link_style } = this.props.content;

		return (
			<div className="block cta-block">
				<div className="block-inner">
					<div className="cta">
						{ title !== '' ? <p class="block-title">{ title }</p> : '' }
						{ link_url !== '' ? <a href={link_url} className={link_style}>{ link_text }</a> : '' }
					</div>
				</div>
			</div>
		)
	}
}

export default CTA