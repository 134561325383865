import React, {Component} from 'react'
import PostListing from '../components/PostListing'
import { getPosts, deletePost } from '../api'

class PostListingContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			items: [],
	    	page: 1,
	    	perPage: 10,
	    	orderBy: 'title',
	    	orderDir: 'asc',
	    	totalPages: 1,
	    	isLoading: false,
	    	status: ['active', 'draft'],
	    	deleteSuccess: false,
	    	search: ''
		}

		this.onSearchChange = this.onSearchChange.bind(this)
		this.getPosts = this.getPosts.bind(this)
		this.setOrderBy = this.setOrderBy.bind(this)
		this.setPage = this.setPage.bind(this)
		this.delete = this.delete.bind(this)
	}

	onSearchChange(name, value) {
		this.setState({
			search: value
		})
	}

	async getPosts(data) {
		this.setState({
			isLoading: true
		})

	    await getPosts(data)
	    	.then(response => {
	    		this.setState({
					isLoading: false,
					totalPages: response.data.meta.last_page,
					items: response.data.data
				})
	    	})
	    	.catch(error => {
		    	this.setState({
					isLoading: false
				})
	    	}) 
	}

	setOrderBy(orderBy, orderDir) {
		this.setState({
			orderBy: orderBy,
			orderDir: orderDir
		})
	}

	setPage(page) {
		this.setState({
			page: page
		})
	}

	async delete(id) {
		this.setState({
			isLoading: true,
			deleteSuccess: false
		})

	    await deletePost(id)
	    	.then(response => {
	    		this.setState({
					isLoading: false,
					deleteSuccess: true
				})
	    	})
	    	.catch(error => {
		    	this.setState({
					isLoading: false,
					deleteSuccess: false
				})
	    	})
	}

	render() {
		return (
			<PostListing 
				loadItems={this.getPosts}
				setOrderBy={this.setOrderBy}
				setPage={this.setPage}
				delete={this.delete}
				{...this.state} />
		)
	}
}

export default PostListingContainer