import { call } from 'API'

export function getEvent(id) {
	return call('events/' + id, 'get', {}, true);
}

export function getEvents(data) {
	return call('events', 'get', data, true);
}

export function saveEvent(id, data) {
	return call('events/' + id, 'put', data, true);
}

export function createEvent(data) {
	return call('events', 'post', data, true);
}

export function deleteEvent(id) {
	return call('events/' + id, 'delete', {}, true);
}

export function getActiveEventDates(data) {
	return call('events/dates/active', 'get', data, true);
}

export function getEventDatesByIDs(data) {
	return call('events/dates', 'get', data, true);
}

export function exportICAL() {
	return call('events/export', 'get', {}, false);
}

export function getEventBySlug(slug) {
	let data = {
		'slug': slug
	}
	return call('events/slug', 'get', data, false);
}