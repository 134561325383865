import {
	GET_FORM_REQUEST,
	GET_FORM_SUCCESS,
	GET_FORM_FAILURE,
	SAVE_FORM_REQUEST,
	SAVE_FORM_SUCCESS,
	SAVE_FORM_VALIDATION,
	SAVE_FORM_FAILURE,
	DELETE_FORM_REQUEST,
	DELETE_FORM_SUCCESS,
	DELETE_FORM_FAILURE,
	GET_FORMS_REQUEST,
	GET_FORMS_SUCCESS,
	GET_FORMS_FAILURE,
	SET_FORMS_ORDER,
	SET_FORMS_PAGE,
	SET_FORMS_SEARCH,
} from './actionTypes'

import { handleErrors } from 'core/modules/Error/actions'
import API from 'API'

function requestGetForm() {
	return {
		type: GET_FORM_REQUEST,
		isFetching: true
	}
}

export function receiveGetForm(form) {
	return {
		type: GET_FORM_SUCCESS,
		isFetching: false,
		form
	}
}

export function getFormError() {
	return {
		type: GET_FORM_FAILURE,
		isFetching: false
	}
}

export function getForm(id) {
	return dispatch => {
		// Before ajax call
		dispatch(requestGetForm())
		let CLAPI = new API(dispatch);
		return CLAPI.getForm(id)
			.then(response => {
				// console.log(response);
				// console.log(response.data);
                dispatch(receiveGetForm(response.data.data));
                // return response
            })
            .catch(error => {
            	dispatch(getFormError());
            	dispatch(handleErrors(error));
            });
	}
}

function requestSaveForm() {
	return {
		type: SAVE_FORM_REQUEST,
		isFetching: true
	}
}

export function receiveSaveForm(form) {
	return {
		type: SAVE_FORM_SUCCESS,
		isFetching: false,
		form
	}
}

export function saveFormValidation(errors) {
	// console.log(errors);
	return {
		type: SAVE_FORM_VALIDATION,
		isFetching: false,
		errors: errors
	}
}

export function saveFormError() {
	return {
		type: SAVE_FORM_FAILURE,
		isFetching: false
	}
}

export function saveForm(id, data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestSaveForm())
		let CLAPI = new API(dispatch);
		return CLAPI.saveForm(id, data)
			.then(response => {
				// console.log(response);
                dispatch(receiveSaveForm(response.data.data));
                // return response
            })
            .catch(error => {
            	if(error.response && error.response.status === 422) {
            		// console.log(error.response);
            		dispatch(saveFormValidation(error.response.data.errors));
            	} else {
            		dispatch(saveFormError());
            		dispatch(handleErrors(error));
            	}
            });
	}
}

export function createForm(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestSaveForm())
		let CLAPI = new API(dispatch);
		return CLAPI.createForm(data)
			.then(response => {
				// console.log(response);
                dispatch(receiveSaveForm(response.data.data));
                // return response
            })
            .catch(error => {
            	if(error.response && error.response.status === 422) {
            		// console.log(error.response);
            		dispatch(saveFormValidation(error.response.data.errors));
            	} else {
            		dispatch(saveFormError());
            		dispatch(handleErrors(error));
            	}
            });
	}
}

function requestDeleteForm() {
	return {
		type: DELETE_FORM_REQUEST,
		isFetching: true
	}
}

export function receiveDeleteForm() {
	return {
		type: DELETE_FORM_SUCCESS,
		isFetching: false
	}
}

export function saveDeleteFormError() {
	return {
		type: DELETE_FORM_FAILURE,
		isFetching: false
	}
}

export function deleteForm(id, data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestDeleteForm())
		let CLAPI = new API(dispatch);
		return CLAPI.deleteForm(id, data)
			.then(response => {
                dispatch(receiveDeleteForm());
            })
            .catch(error => {
            	dispatch(saveDeleteFormError());
            	dispatch(handleErrors(error));
            });
	}
}

function requestGetForms() {
	return {
		type: GET_FORMS_REQUEST,
		isFetching: true
	}
}

export function receiveGetForms(forms, totalPages) {
	return {
		type: GET_FORMS_SUCCESS,
		isFetching: false,
		forms,
		totalPages
	}
}

export function getFormsError() {
	return {
		type: GET_FORMS_FAILURE,
		isFetching: false
	}
}

export function fetchForms(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestGetForms())
		let CLAPI = new API(dispatch);
		return CLAPI.getForms(data)
			.then(response => {
				// console.log(response);
				// console.log(response.data);
                dispatch(receiveGetForms(response.data.data, response.data.meta.last_page));
                // return response
            })
            .catch(error => {
            	dispatch(getFormsError());
            	dispatch(handleErrors(error));
            });
	}
}

export function setFormsOrder(orderBy, orderDir) {
	return {
		type: SET_FORMS_ORDER,
		orderBy,
		orderDir
	}
}

export function setFormsPage(page) {
	return {
		type: SET_FORMS_PAGE,
		page
	}
}

export function setFormsSearch(search) {
	return {
		type: SET_FORMS_SEARCH,
		search
	}
}