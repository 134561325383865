import React, {Component} from 'react'
import moment from 'moment'

class PostList extends Component {
	render() {
		return (
			<div className="post-list">
				{ this.props.items.map((post) => {
					let { title, content, slug, publish_start } = post;
					return (
						<div className="post">
							<div className="post-date">{ moment(publish_start).format('MMMM D, YYYY | h:mmA') }</div>
							<h2 className="post-title">{ title }</h2>
							<div className="post-excerpt" dangerouslySetInnerHTML={{__html: content.substring(0, 250)}}></div>
							<a href={"/news/" + slug} className="large-arrow-link">Read More</a>
						</div>
					)
				})}
			</div>
		)
	}
}

export default PostList