import React, { Component } from 'react';
import moment from 'moment'
import Loader from 'admin/components/Loader'
import { Link } from 'react-router-dom'
import API from 'API'

class FormSubmission extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			submission: null
		}
	}

	async getSubmission(id) {
		if(id === undefined)
			return false;

	    let theAPI = new API();
	    const submission = await theAPI.getFormSubmission(id);
	    let submissionData = submission.data.data

	    this.setState({
	    	isLoading: false,
	    	submission: submissionData
	    });
	}

	componentDidMount() {
		let submissionID = this.props.match.params.id;
		if(submissionID !== undefined) {
			this.getSubmission(submissionID);
		}
	}

	render() {
		let submission = this.state.submission;

		let values = [];
		if(submission !== null) {
			values = Object.entries(JSON.parse(submission.values));
		}
		return (
			<div className="main-content detail">
				{
					this.state.isLoading ?
					<Loader/>
					:
					<React.Fragment>				
						<Link to={"/admin/dashboard/form/editor/" + submission.form_id} className="back-link">Back to Form</Link>
						<h1 className="detail-title">Edit {moment.utc(submission.created_at).local().format('MMMM D, YYYY - hh:mm a')}</h1>
						<ul className="detail-list">
						{
							values.map((value, i) => {
								let label = value[0];
								let content = value[1];

								let displayValue = content;
								if(Array.isArray(content)) {
									displayValue = (
										<ul>
											{content.map((v) => {
												return <li>{v}</li>
											})}
										</ul>
									)
								}
								return (
									<li>
										<p className="detail-label">{label}</p>
										<div className="detail-content">
											{displayValue}
										</div>
									</li>
								)
							})
						}
						</ul>
					</React.Fragment>
				}
			</div>
		)
	}
}

export default FormSubmission;