import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import moment from 'moment'

class FormForm extends Component {
	render() {
		const { values, errors } = this.props
		let submissions = [];
		if(this.props.editValues !== undefined && this.props.editValues.submissions !== undefined)
			submissions = this.props.editValues.submissions

		let columns = [
			{
				id: 'created_at',
				display: 'Date',
				value: item => {
					return moment.utc(item.created_at).local().format('MMMM D, YYYY - hh:mm a')
				}
			},
			{
				id: 'actions',
				display: '',
				value: item => {
					return <a className="button" href={"/admin/dashboard/form/submission/" + item.id}>View Submission</a>
				}
			}
		];

		return (
			<div className="main-content">
				<form className="login-form" onSubmit={this.props.onSubmit}>
					<fieldset>
						<p className="form-title">Form</p>
						<div className="field-group">
							<Input type="text" name="name" label="Name" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.name} errors={errors.name} />
							<Input type="text" name="notification_email" label="Notification Email" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.notification_email} errors={errors.notification_email} />
						</div>
					</fieldset>
					{submissions.length > 0 ?
						<fieldset>
							<p className="form-title">Submissions</p>
							<div className={"listing"}>
								<div className="model-list">
									<table>
										<thead>
											<tr>
												{columns.map((column, i) => {
													return <th key={column.id}>{column.display}</th>
												})}
											</tr>
										</thead>
										<tbody>
											{submissions.map((item, key) => {

												return <tr key={key}>
															{columns.map((column, i) => { 
																let value = item[column.id];
																if(column.value !== undefined)
																	value = column.value(item)
																return <td key={i}>{value}</td>
															})}
														</tr>
												}
											)}
											
										</tbody>
									</table>
								</div>
							</div>
						</fieldset>
					: '' }
				</form>
			</div>
		)
	}
}

export default FormForm;