import * as React from 'react';
import withInstagramFeed from 'origen-react-instagram-feed';

const styles = () => ({
  wrapper: {},
  image: {
    width: '100%',
    height: '100%',
  },
});

export type Props = {
  media?: Array<{
    displayImage: string,
    id?: string,
    postLink?: string,
    accessibilityCaption?: string,
  }>,
  account: string,
  status: 'completed' | 'loading' | 'failed',
};

const InstaGrid = ({media, account, status}: Props) => {
  return (
    <div className="insta-grid">
      {media &&
        status === 'completed' &&
        media.map(({ displayImage, id, postLink, accessibilityCaption }) => (
            <a href={postLink || `https://www.instagram.com/${account}/`} style={{ backgroundImage: 'url(' + displayImage +')'}} className="insta-post-image"></a>
        ))}
      {status === 'loading' && <p>loading...</p>}
      {status === 'failed' && <p>Check instagram here</p>}
    </div>
  );
};

InstaGrid.defaultProps = {
  media: undefined,
};

export default withInstagramFeed(InstaGrid);