import React, { Component } from 'react'
import { default as Header } from 'frontend/containers/layout/HeaderContainer';

import PageContainer from 'frontend/modules/Page/containers/PageContainer';
import PostContainer from 'frontend/modules/Post/containers/PostContainer';
import EventContainer from 'frontend/modules/Event/containers/EventContainer';
import NotFoundPage from 'frontend/components/pages/NotFoundPage';
import { Switch, Route} from 'react-router-dom';

import 'frontend/assets/scss/frontend.scss';

class FrontEnd extends Component {
	render() {
		return (
			<div className="front-end page">
				<div className="page-main">
					<Header />
						<Switch>
							{/*<Route exact path="/" render={() => <Redirect to="/home"/>}/>*/}
						    <Route path="/404" component={NotFoundPage} />
						    <Route path="/news/:slug" component={PostContainer} />
						    <Route path="/events/:slug" component={EventContainer} />
						    <Route component={PageContainer} />
						</Switch>
				</div>
				
			</div>
		)
	}
}

export default FrontEnd