import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getSetting } from 'admin/modules/Settings/selectors'
import { default as Footer } from 'frontend/containers/layout/FooterContainer';

class NotFoundPage extends Component {
	componentDidMount() {
	  document.title = 'SaskTel Centre - 404 Not Found';
	}

	render() {
		return (
			<div className="post-page dark-secondary error-page">
				{this.props.errorImage !== null ? 
					<div className="featured-image" style={{ backgroundImage: 'url(' + this.props.errorImage.thumbnailUrl + '?w=1920)'}}>
						<div className="featured-content">
							<p className="error-subtitle">{ this.props.errorSubtitle }</p>
							<h1 className="error-title">{ this.props.errorTitle }</h1>
							<a href={this.props.errorLinkURL} className="button secondary">{ this.props.errorLinkText }</a>
						</div>
					</div> : '' }
				<div class="curved">
					<div class="curved-inner">
						<Footer />
					</div>
				</div>
				
			</div>
		)
	}
}

function mapStateToProps(state) {
  return {
    errorImage: JSON.parse(getSetting(state, 'error_image')),
    errorTitle: getSetting(state, 'error_title'),
    errorSubtitle: getSetting(state, 'error_subtitle'),
    errorLinkText: getSetting(state, 'error_link_text'),
    errorLinkURL: getSetting(state, 'error_link_url'),
  }
}

export default connect(mapStateToProps)(NotFoundPage)