import React, { Component } from 'react';
import moment from 'moment'
import Select from 'core/components/form/Select'
import MultiRowWrapper from 'core/components/form/MultiRowWrapper'
import { getActiveEventDates } from 'admin/modules/Event/api'

class EventForm extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
	    	events: []
	    }
	}

	async getEvents() {
	    const events = await getActiveEventDates({
	    	'afterDate': moment().format('YYYY-MM-DD')
	    });

	    let eventData = events.data.data

	    this.setState({
	    	events: eventData
	    });
	}

	componentDidMount() {
		this.getEvents();
	}

	render() {
		return (
			<div className="repeater">
				
				{this.props.rows.map((bucket, i) => {
					return (
						<div className="repeater-section" key={i}>
							<Select name="event_id" label="Event" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={bucket.event_id}>
								<option value=""> -- Select an Event -- </option>
								{this.state.events.map((event, i) => {
									let displayName = event.event.title + ' - ' + moment(event.start).format('MMMM D, YYYY')
									return <option value={event.id}>{displayName}</option>
								})}
							</Select>
							
							<button type="button" className="remove" onClick={() => this.props.removeRow(i)}></button>
						</div>
					)
				})}
				<button type="button" className="add" onClick={() => this.props.addRow()}>
					Add Event <span>+</span>
				</button>
			</div>
		)
	}
} 

const EventsField = MultiRowWrapper(EventForm, {
		event_id: 0
    });

export const fieldDefaults = {
	'events': []
}

export class FeaturedEvents extends Component {
	render() {
		return (
			<div className="block">
				<p className="block-title">Featured Events</p>
				<div className="block-fields">
					<EventsField name="events" onChange={this.props.onChange} rows={this.props.content.events} />
				</div>
			</div>
		);
	}
}