import React, { Component } from 'react'
import AccordionItem from './block-parts/AccordionItem'

class Accordion extends Component {
	render() {
		let { title, content, items, background, no_content_title, no_content_subtitle } = this.props.content

		return (
			<div className={"block accordion-block " + background}>
				<div className="block-inner">
					<div className="block-content">
						{title !== "" ? <h2 className="block-title">{title}</h2> : ''}
						{content !== "" ? <div className="block-content" dangerouslySetInnerHTML={{__html: content}}></div> : ''}
					</div>
					<div className="accordion-wrapper">
						<ul className="accordion">
							{items.map((item, i) => {
								const { title, content } = item;
								return (
									<AccordionItem key={i} title={title} content={content} />
								);
							})}
						</ul>
						{ items.length == 0 ? 
							<div className="no-items">
								{no_content_title !== "" ? <h3 className="no-items-title">{no_content_title}</h3> : ''}
								{no_content_subtitle !== "" ? <p className="no-items-subtitle">{no_content_subtitle}</p> : ''}
							</div>
						: '' }
					</div>
				</div>
			</div>
		)
	}
}

export default Accordion