import React, {Component} from 'react'
import PostList from 'frontend/modules/Post/components/PostList'
import { getActivePosts } from 'admin/modules/Post/api'
import moment from 'moment'

class News extends Component {
	constructor(props) {
		super(props);

		this.state = {
			items: [],
	    	page: 1,
	    	perPage: 8,
	    	orderBy: 'publish_start',
	    	orderDir: 'desc',
	    	totalPages: 1,
	    	isLoading: false,
	    	showLoadMore: true
		}

		this.loadPosts = this.loadPosts.bind(this)
		this.loadMore = this.loadMore.bind(this)
	}

	componentDidMount() {
		this.loadPosts();
	}

	async loadPosts() {
		this.setState({
			isLoading: true
		})

		let data = {
			page: this.state.page,
			perPage: this.state.perPage,
			orderBy: this.state.orderBy,
			orderDir: this.state.orderDir,
			publishDate: moment().format('YYYY-MM-DD HH:mm:ss')
		}

	    await getActivePosts(data)
	    	.then(response => {
	    		let showLoadMore = true
	    		if(this.state.page >= response.data.meta.last_page)
	    			showLoadMore = false
	    		this.setState({
					isLoading: false,
					totalPages: response.data.meta.last_page,
					items: [ ...this.state.items, ...response.data.data],
					page: this.state.page,
					showLoadMore: showLoadMore
				})
	    	})
	    	.catch(error => {
		    	this.setState({
					isLoading: false
				})
	    	}) 
	}

	loadMore() {
		this.setState({
			page: this.state.page + 1
		}, () => this.loadPosts());
	}

	render() {
		return (
			<div className="block news-block">
				<PostList items={this.state.items} />
				{ this.state.showLoadMore ? <button className="button" onClick={this.loadMore}>Load Older</button> : '' }
			</div>
		)
	}
}

export default News