import React, {Component} from 'react'
import moment from 'moment'
import EventListing from '../components/EventListing'
import { getActiveEventDates } from 'admin/modules/Event/api'

class News extends Component {
	constructor(props) {
		super(props);

		this.state = {
			items: [],
	    	page: 1,
	    	perPage: 16,
	    	orderBy: 'start',
	    	orderDir: 'asc',
	    	totalPages: 1,
	    	isLoading: false,
	    	showLoadMore: true
		}

		this.loadEvents = this.loadEvents.bind(this)
		this.loadMore = this.loadMore.bind(this)
	}

	componentDidMount() {
		this.loadEvents();
	}

	async loadEvents() {
		this.setState({
			isLoading: true
		})

		let data = {
			afterDate: moment().format('YYYY-MM-DD'),
			page: this.state.page,
			perPage: this.state.perPage,
			orderBy: this.state.orderBy,
			orderDir: this.state.orderDir
		}

	    await getActiveEventDates(data)
	    	.then(response => {
	    		let showLoadMore = true
	    		if(this.state.page >= response.data.meta.last_page)
	    			showLoadMore = false
	    		this.setState({
					isLoading: false,
					totalPages: response.data.meta.last_page,
					items: [ ...this.state.items, ...response.data.data],
					page: this.state.page,
					showLoadMore: showLoadMore
				})
	    	})
	    	.catch(error => {
		    	this.setState({
					isLoading: false
				})
	    	}) 
	}

	loadMore() {
		this.setState({
			page: this.state.page + 1
		}, () => this.loadEvents());
	}

	render() {
		return (
			<div className="event-listing-wrapper">
				<EventListing items={this.state.items} />
				{ this.state.showLoadMore ? <button className="button load-more" onClick={this.loadMore}>Load More Events</button> : '' }
			</div>
		)
	}
}

export default News