import {
	GET_AUDITS_REQUEST,
	GET_AUDITS_SUCCESS,
	GET_AUDITS_FAILURE,
	SET_AUDITS_ORDER,
	SET_AUDITS_PAGE,
	SET_AUDITS_SEARCH
} from './actionTypes'

import { handleErrors } from 'core/modules/Error/actions'
import API from 'API'

function requestGetAudits() {
	return {
		type: GET_AUDITS_REQUEST,
		isFetching: true
	}
}

export function receiveGetAudits(audits, totalPages) {
	return {
		type: GET_AUDITS_SUCCESS,
		isFetching: false,
		audits,
		totalPages
	}
}

export function getAuditsError() {
	return {
		type: GET_AUDITS_FAILURE,
		isFetching: false
	}
}

export function fetchAudits(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestGetAudits())
		let CLAPI = new API(dispatch);
		return CLAPI.getAudits(data)
			.then(response => {
				// console.log(response);
				// console.log(response.data);
                dispatch(receiveGetAudits(response.data.data, response.data.meta.last_page));
                // return response
            })
            .catch(error => {
            	dispatch(getAuditsError());
            	dispatch(handleErrors(error));
            });
	}
}

export function setAuditsOrder(orderBy, orderDir) {
	return {
		type: SET_AUDITS_ORDER,
		orderBy,
		orderDir
	}
}

export function setAuditsPage(page) {
	return {
		type: SET_AUDITS_PAGE,
		page
	}
}

export function setAuditsSearch(search) {
	return {
		type: SET_AUDITS_SEARCH,
		search
	}
}