import React, { Component } from 'react'
import MenuContainer from 'frontend/modules/Menu/containers/MenuContainer'

class Footer extends Component {
	render() {
		let currentDate = new Date();

		return (
			<footer className="page-footer">
				<div className="sponsors">
					<h2 className="sponsors-title">{ this.props.sponsorsTitle }</h2>
					<div className="sponsor-list">
						{ this.props.sponsors.map((sponsor, i) => {
							return <img src={sponsor.logo.thumbnailUrl + '?w=230&h=120&fit=contain'} key={i} />
						})}
					</div>
				</div>
				<div className="footer-main-wrapper">
					<div className="footer-main">
						<div className="footer-main-inner">
							<a href="/" className="logo-wrapper">
								<img src={this.props.logo.thumbnailUrl + '?w=260'} alt="SPMA" className="logo" />
							</a>
							<MenuContainer id={6} className="footer-menu" />
							<a className="suite-life-button button" href={this.props.suiteLifeURL}>
								<img src={this.props.suiteLifeLogo.thumbnailUrl + '?w=200'} alt="Suite Life" className="logo" />
								<span>Login</span>
							</a>
						</div>
					</div>
				</div>
				<div className="footer-bottom-wrapper">
					<div className="footer-bottom">
						<div className="footer-bottom-inner">
							<MenuContainer id={5} />
							<p className="copyright">{this.props.copyright.replace('@year@', currentDate.getFullYear())}</p>
							<MenuContainer id={7} className="footer-bottom-menu" />
						</div>
					</div>
				</div>
			</footer>
		)
	}
}

export default Footer