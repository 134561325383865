import {
	GET_PAGE_REQUEST,
	GET_PAGE_SUCCESS,
	GET_PAGE_FAILURE,
	SAVE_PAGE_REQUEST,
	SAVE_PAGE_SUCCESS,
	SAVE_PAGE_VALIDATION,
	SAVE_PAGE_FAILURE,
	DELETE_PAGE_REQUEST,
	DELETE_PAGE_SUCCESS,
	DELETE_PAGE_FAILURE,
	GET_PAGES_REQUEST,
	GET_PAGES_SUCCESS,
	GET_PAGES_FAILURE,
	SET_PAGES_ORDER,
	SET_PAGES_PAGE,
	SET_PAGES_SEARCH,
} from './actionTypes'

import { handleErrors } from 'core/modules/Error/actions'
import API from 'API'

function requestGetPage() {
	return {
		type: GET_PAGE_REQUEST,
		isFetching: true
	}
}

export function receiveGetPage(page) {
	return {
		type: GET_PAGE_SUCCESS,
		isFetching: false,
		page
	}
}

export function getPageError() {
	return {
		type: GET_PAGE_FAILURE,
		isFetching: false
	}
}

export function getPage(id) {
	return dispatch => {
		// Before ajax call
		dispatch(requestGetPage())
		let CLAPI = new API(dispatch);
		return CLAPI.getPage(id)
			.then(response => {
				// console.log(response);
				// console.log(response.data);
                dispatch(receiveGetPage(response.data.data));
                // return response
            })
            .catch(error => {
            	dispatch(getPageError());
            	dispatch(handleErrors(error));
            });
	}
}

function requestSavePage() {
	return {
		type: SAVE_PAGE_REQUEST,
		isFetching: true
	}
}

export function receiveSavePage(page) {
	return {
		type: SAVE_PAGE_SUCCESS,
		isFetching: false,
		page
	}
}

export function savePageValidation(errors) {
	// console.log(errors);
	return {
		type: SAVE_PAGE_VALIDATION,
		isFetching: false,
		errors: errors
	}
}

export function savePageError() {
	return {
		type: SAVE_PAGE_FAILURE,
		isFetching: false
	}
}

export function savePage(id, data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestSavePage())
		let CLAPI = new API(dispatch);
		return CLAPI.savePage(id, data)
			.then(response => {
				// console.log(response);
                dispatch(receiveSavePage(response.data.data));
                // return response
            })
            .catch(error => {
            	if(error.response && error.response.status === 422) {
            		// console.log(error.response);
            		dispatch(savePageValidation(error.response.data.errors));
            	} else {
            		dispatch(savePageError());
            		dispatch(handleErrors(error));
            	}
            });
	}
}

export function createPage(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestSavePage())
		let CLAPI = new API(dispatch);
		return CLAPI.createPage(data)
			.then(response => {
				// console.log(response);
                dispatch(receiveSavePage(response.data.data));
                // return response
            })
            .catch(error => {
            	if(error.response && error.response.status === 422) {
            		// console.log(error.response);
            		dispatch(savePageValidation(error.response.data.errors));
            	} else {
            		dispatch(savePageError());
            		dispatch(handleErrors(error));
            	}
            });
	}
}

function requestDeletePage() {
	return {
		type: DELETE_PAGE_REQUEST,
		isFetching: true
	}
}

export function receiveDeletePage() {
	return {
		type: DELETE_PAGE_SUCCESS,
		isFetching: false
	}
}

export function saveDeletePageError() {
	return {
		type: DELETE_PAGE_FAILURE,
		isFetching: false
	}
}

export function deletePage(id, data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestDeletePage())
		let CLAPI = new API(dispatch);
		return CLAPI.deletePage(id, data)
			.then(response => {
                dispatch(receiveDeletePage());
            })
            .catch(error => {
            	dispatch(saveDeletePageError());
            	dispatch(handleErrors(error));
            });
	}
}

function requestGetPages() {
	return {
		type: GET_PAGES_REQUEST,
		isFetching: true
	}
}

export function receiveGetPages(pages, totalPages) {
	return {
		type: GET_PAGES_SUCCESS,
		isFetching: false,
		pages,
		totalPages
	}
}

export function getPagesError() {
	return {
		type: GET_PAGES_FAILURE,
		isFetching: false
	}
}

export function fetchPages(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestGetPages())
		let CLAPI = new API(dispatch);
		return CLAPI.getPages(data)
			.then(response => {
				// console.log(response);
				// console.log(response.data);
                dispatch(receiveGetPages(response.data.data, response.data.meta.last_page));
                // return response
            })
            .catch(error => {
            	dispatch(getPagesError());
            	dispatch(handleErrors(error));
            });
	}
}

export function setPagesOrder(orderBy, orderDir) {
	return {
		type: SET_PAGES_ORDER,
		orderBy,
		orderDir
	}
}

export function setPagesPage(page) {
	return {
		type: SET_PAGES_PAGE,
		page
	}
}

export function setPagesSearch(search) {
	return {
		type: SET_PAGES_SEARCH,
		search
	}
}