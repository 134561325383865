import React, { Component } from "react";
import CalendarEvent from "./CalendarEvent";
import CalendarToolbar from "./CalendarToolbar";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

class EventCalendar extends Component {
  render() {
    let components = {
      event: CalendarEvent,
    };

    return (
      <div className="event-calendar">
        <div className="event-calendar-inner">
          <Calendar
            components={components}
            localizer={localizer}
            events={this.props.items}
            titleAccessor={(event) => event.event.title}
            startAccessor={(event) => new Date(event.start)}
            endAccessor={(event) => {
              let end = new Date(event.end);
              if (end == "Invalid Date") return new Date(event.start);
              return end;
            }}
            style={{ height: 1000 }}
            drilldownView="null"
            popup={true}
            onSelectEvent={(event) => {
              window.location = "events/" + event.event.slug;
            }}
          />
        </div>
      </div>
    );
  }
}

export default EventCalendar;
