import React, { Component } from 'react'
import API from 'API'
import Menu from '../components/Menu'

class MenuContainer extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
	    	title: '',
	    	items: []
	    }
	}

	async getMenu(id) {
		if(id === undefined)
			return false;

	    let theAPI = new API();
	    const menu = await theAPI.getMenu(id);
	    let menuData = menu.data.data

	    this.setState({
	    	title: menuData.title,
	    	items: menuData.items,
	    	savedClass: menuData.class
	    });
	}

	componentDidMount() {
		if(this.props.id !== undefined) {
			this.getMenu(this.props.id);
		}
	}

	render() {
		const { title, items, savedClass } = this.state

		return (
			<Menu title={title} savedClass={savedClass} items={items} {...this.props} />
		)
	}
}

export default MenuContainer