export const getFormsErrors = (state) => state.forms.errors
export const getFormsFetching = (state) => state.forms.isFetching
export const getCurrentForm = (state) => state.forms.currentForm
export const getFormSaveSuccess = (state) => state.forms.saveSuccess
export const getFormDeleteSuccess = (state) => state.forms.deleteSuccess

export const getForms = (state) => state.forms.forms
export const getFormsPage = (state) => state.forms.page
export const getFormsPerPage = (state) => state.forms.perPage
export const getFormsOrderBy = (state) => state.forms.orderBy
export const getFormsOrderDir = (state) => state.forms.orderDir
export const getFormsTotalPages = (state) => state.forms.totalPages
export const getFormsStatus = (state) => state.forms.status
export const getFormsSearch = (state) => state.forms.search