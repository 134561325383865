// import React, {Component} from 'react'
import { connect } from 'react-redux'
import Footer from 'frontend/components/layout/Footer'
import { getSetting } from 'admin/modules/Settings/selectors'

function mapStateToProps(state) {
  return {
    suiteLifeLogo: JSON.parse(getSetting(state, 'suitelife_logo')),
    suiteLifeURL: getSetting(state, 'suitelife_url'),
    copyright: getSetting(state, 'copyright'),
    sponsors: JSON.parse(getSetting(state, 'sponsors')),
    sponsorsTitle: getSetting(state, 'sponsors_title'),
    logo: JSON.parse(getSetting(state, 'logo'))
  }
}


export default connect(mapStateToProps)(Footer)