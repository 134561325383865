import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import { errorMessage } from 'core/modules/Error/reducers'
import { auth, passwordReset, passwordResetEmail } from 'admin/modules/Auth/reducers'
import { audits } from 'admin/modules/Audit/reducers'
import { forms } from 'admin/modules/Form/reducers'
import { menus } from 'admin/modules/Menu/reducers'
import { pages } from 'admin/modules/Page/reducers'
import { settings } from 'admin/modules/Settings/reducers'
import { users } from 'admin/modules/User/reducers'

export default (history) => combineReducers({
	router: connectRouter(history),
	audits,
	auth,
	errorMessage,
	forms,
	menus,
	pages,
	passwordReset,
	passwordResetEmail,
	settings,
	users
})