import React from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function DraggableRowWrapper(Component, emptyRow) {
    class MultiRow extends Component {
        constructor(props) {
            super(props);

            this.onRowChange = this.onRowChange.bind(this);
            this.removeRow = this.removeRow.bind(this);
            this.addRow = this.addRow.bind(this);
            this.reorder = this.reorder.bind(this);
            this.onDragEnd = this.onDragEnd.bind(this);
        }



        onRowChange(index, name, value) {
            let updatedRows = JSON.parse(JSON.stringify(this.props.rows));
            if(updatedRows[index] !== undefined) {
                let currentRow = updatedRows[index];

                currentRow[name] = value;
            }
            this.props.onChange(this.props.name, updatedRows)
        }

        removeRow(index) {
            let updatedRows = JSON.parse(JSON.stringify(this.props.rows));
            updatedRows.splice(index, 1);
            this.props.onChange(this.props.name, updatedRows)
        }

        addRow(rowValue) {
            // console.log(rowValue);
            let newRow = emptyRow;
            if(rowValue !== undefined)
                newRow = rowValue;
            let updatedRows = JSON.parse(JSON.stringify(this.props.rows));
            updatedRows.push(JSON.parse(JSON.stringify(newRow)));
            this.props.onChange(this.props.name, updatedRows)
        }

        reorder(startIndex, endIndex) {
            let updatedRows = JSON.parse(JSON.stringify(this.props.rows));
            const [removed] = updatedRows.splice(startIndex, 1);
            updatedRows.splice(endIndex, 0, removed);
            this.props.onChange(this.props.name, updatedRows)
        }

        onDragEnd(result) {
            // dropped outside the list
            if (!result.destination) {
              return;
            }

            this.reorder(
              result.source.index,
              result.destination.index
            );
         }

        render() {
            // return <Component 
            //             onRowChange={this.onRowChange}
            //             addRow={this.addRow}
            //             removeRow={this.removeRow}
            //             reorder={this.reorder}
            //             {...this.props} />
            return (
                        <div className="repeater">
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                            <div
                                              {...provided.droppableProps}
                                              ref={provided.innerRef}
                                              className={ (snapshot.isDraggingOver ? 'active-dragging ' : '') }
                                            >
                                                {this.props.rows.map((item, i) => {
                                                    return (
                                                        <React.Fragment>
                                                        { !this.props.expanded ? 
                                                            <Draggable key={item.title+i} draggableId={item.title+i} index={i}>
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                      ref={provided.innerRef}
                                                                      {...provided.draggableProps}
                                                                      {...provided.dragHandleProps}
                                                                      style={provided.draggableProps.style}
                                                                      className={ (snapshot.isDragging ? 'is-dragging ' : '') }
                                                                    > 
                                                                        <Component index={i} onRowChange={this.onRowChange} item={item} removeRow={this.removeRow} expanded={this.props.expanded} {...this.props} />
                                                                    </div>
                                                                )}
                                                             </Draggable> 
                                                             : 
                                                            <Component index={i} onRowChange={this.onRowChange} item={item} removeRow={this.removeRow} expanded={this.props.expanded} {...this.props} />
                                                        }
                                                        </React.Fragment>
                                                    )
                                                })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <button type="button" className="add" onClick={() => this.addRow()}>
                                Add Accordion Item <span>+</span>
                            </button>
                        </div>
                    )
        }
    }

    return MultiRow;
}

export default DraggableRowWrapper;