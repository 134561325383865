import React, { Component } from 'react'
import AccordionItem from './block-parts/AccordionItem'

class ContentWithBuckets extends Component {
	render() {
		let { title, content, buckets } = this.props.content

		return (
			<div className="block content-with-buckets-block ">
				<div className="block-inner">
					<div className="block-content">
						{title !== "" ? <h2 className="block-title">{title}</h2> : ''}
						{content !== "" ? <div className="block-content" dangerouslySetInnerHTML={{__html: content}}></div> : ''}
					</div>
					<ul className="buckets">
						{buckets .map((bucket, i) => {
							const { content } = bucket;
							return (
								<div className="bucket">{ content }</div>
							);
						})}
					</ul>
				</div>
			</div>
		)
	}
}

export default ContentWithBuckets