import React, {Component} from 'react'

class CalendarToolbar extends Component {
	previous() {

	}

	next() {
		
	}

	render() {
		console.log(this.props)
		return (
			<div className="toolbar">
				<button className="calendar-button prev"></button>
				<div className="current-month">{ this.props.label }</div>
				<button className="calendar-button next"></button>
			</div>
		)
	}
}

export default CalendarToolbar