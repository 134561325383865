import React, { Component } from 'react';
import Input from 'core/components/form/Input'

export const fieldDefaults = {
	'latitude': '',
	'longitude': '',
	'address': '',
	'link_text': '',
	'link_url': ''
}

export class Map extends Component {
	render() {
		return (
			<div className="block">
				<p className="block-title">Map</p>
				<div className="block-fields">
					<Input type="text" name="address" label="Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.address} />
					<div className="field-group">
						<Input type="text" name="latitude" label="Latitude" onInputChange={this.props.onChange} value={this.props.content.latitude} />
						<Input type="text" name="longitude" label="Longitude" onInputChange={this.props.onChange} value={this.props.content.longitude} />
					</div>
					<div className="field-group">
						<Input type="text" name="link_text" label="Link Text" onInputChange={this.props.onChange} value={this.props.content.link_text} />
						<Input type="text" name="link_url" label="Link URL" onInputChange={this.props.onChange} value={this.props.content.link_url} />
					</div>
				</div>
			</div>
		);
	}
}