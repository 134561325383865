import React, { Component } from 'react'
import MenuItem from './MenuItem'

class Menu extends Component {
	render() {
		return (
			<nav className={'menu ' + this.props.className + ' ' + this.props.savedClass }>
				{this.props.items.map((item, i) => {
					let theme = ''
					if(this.props.id == 1)
						theme = 'main-menu-theme'
					return <MenuItem key={i} url={item.url} target={item.target} title={item.title} class={item.class} items={item.items} customFields={item.custom_fields} theme={theme} onItemHover={this.props.onItemHover} onItemHoverOut={this.props.onItemHoverOut} />
				})}
			</nav>
		)
	}
}

export default Menu