import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Dropdown from 'frontend/components/Dropdown'

class ImageSelector extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selected: 0
		}

		this.onDropdownChange = this.onDropdownChange.bind(this)
	}

	onDropdownChange(value) {
		this.setState({
			selected: value
		})
	}

	render() {
		let { items } = this.props.content;

		let dropdownOptions = [];
		items.forEach(item => {
			dropdownOptions.push(item.title);
		})

		return (
			<div className="block image-selector-block">
				<Dropdown items={dropdownOptions} selected={this.state.selected} onChange={this.onDropdownChange} />
				{ items.map((item, i) => {
					let { image, title, url } = item;
					if(i == this.state.selected) {
						return (
							<div className="image-wrapper">
								<img src={image.thumbnailUrl + '?h=800&fit=contain'} />
							</div>
						)
					}

					return false;
				})}
			</div>
		)
	}
}

export default withRouter(ImageSelector)