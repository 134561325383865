import React, { Component } from 'react'
import moment from 'moment'
import EventListing from './EventListing'
import { default as Footer } from 'frontend/containers/layout/FooterContainer';

class Event extends Component {

	render() {
		return (
			<div className="post-page">
				{this.props.pageImage !== null ? <div className="featured-image" style={{ backgroundImage: 'url(' + this.props.pageImage.thumbnailUrl + '?w=1920)'}}></div> : '' }
				<div class="curved">
					<div class="curved-inner">
						<div className="post-page-inner">
							<a className="large-arrow-link back" href="/events">Back to Events</a>
							<div className="event-page-main">
								<div className="event-main-left">
									{this.props.featuredImage !== "" && this.props.featuredImage !== null ? <img className="event-image" src={this.props.featuredImage.thumbnailUrl + '?w=1000)'} /> : ''}
									<div className="event-main-content">
										<h2 className="event-title">{ this.props.title }</h2>
										<div className="event-excerpt" dangerouslySetInnerHTML={{__html: this.props.content}}></div>
									</div>
								</div>
								<div className="event-main-right">
									{this.props.secondaryImage !== "" && this.props.secondaryImage !== null ? <img className="secondary-image" src={this.props.secondaryImage.thumbnailUrl + '?w=500)'} /> : ''}
									{ this.props.linkURL !== '' && this.props.linkURL !== null ? <a href={this.props.linkURL} className="event-secondary-link button">{ this.props.linkText }</a> : '' }
									{ this.props.secondaryLinkURL !== '' && this.props.secondaryLinkURL !== null ? <a href={this.props.secondaryLinkURL} className="event-secondary-link button">{ this.props.secondaryLinkText }</a> : '' }
									<ul className="event-dates">
										{ this.props.dates.map((date) => {
											return (
												<li>
													<div className="event-date">{ moment(date.start).format('MMMM D, YYYY | h:mmA') }</div>
													{date.tickets_url !== "" ? <a className="event-link button secondary" href={date.tickets_url}>Buy Tickets</a> : ''}
												</li>
											)
										})}
									</ul>
								</div>
							</div>
							{ this.props.otherEvents ? <EventListing items={this.props.otherEvents} /> : '' }
						</div>
						<Footer />
					</div>
				</div>
				
			</div>
		)
	}
}

export default Event