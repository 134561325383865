import React, { Component } from 'react';
import Input from 'core/components/form/Input'

export const fieldDefaults = {
	'title': '',
	'nav_title': ''
}

export class InstagramFeed extends Component {
	render() {
		return (
			<div className="block">
				<p className="block-title">Instagram Feed</p>
				<div className="block-fields">
					<Input type="text" name="title" label="Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.title} />
					<Input type="text" name="nav_title" label="Nav Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.nav_title} />
				</div>
			</div>
		);
	}
}