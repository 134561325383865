import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import DraggableRowWrapper from 'core/components/form/DraggableRowWrapper'
import Select from 'core/components/form/Select'
import WYSIWYG from 'core/components/form/WYSIWYG'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

class AccordionItem extends Component {
	render() {
		let i = this.props.index
		let item = this.props.item
		return (
			<React.Fragment>
				{this.props.expanded ? 
					<div className="repeater-section" key={i}>
						<Input type="text" name="title" label="Accordion Item Title" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={item.title} />
						<WYSIWYG name="content" label="Accordion Item Content" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={item.content} />
						<button type="button" className="remove" onClick={() => this.props.removeRow(i)}></button>
					</div>
					:
					<p className="repeater-reorder">{item.title}</p>
				}
			</React.Fragment>
		)
	}
}

const AccordionItemField = DraggableRowWrapper(AccordionItem, {
        title: '',
        content: ''
    });

export const fieldDefaults = {
	'title': '',
	'content': '',
	'no_content_title': '',
	'no_content_subtitle': '',
	'background': '',
	'items': []
}

export class Accordion extends Component {
	constructor(props) {
		super(props);

		this.state = {
			reorder: false
		}
	}

	render() {
		return (
			<div className="block">
				<p className="block-title">Accordion</p>
				<div className="block-fields">
					<Input type="text" name="title" label="Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.title} />
					<WYSIWYG name="content" label="Content" onInputChange={this.props.onChange} value={this.props.content.content} />
					<Input type="text" name="no_content_title" label="No Content Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.no_content_title} />
					<Input type="text" name="no_content_subtitle" label="No Content Subtitle" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.no_content_subtitle} />
					<Select name="background" label="Background" onInputChange={this.props.onChange} value={this.props.content.background}>
						<option value=""> -- None -- </option>
						<option value="dark-gradient">Dark Gradient</option>
					</Select>
					<button className="button" type="button" onClick={() => this.setState({ reorder: !this.state.reorder }) }>{ (this.state.reorder ? 'Edit' : 'Reorder') }</button>
					<AccordionItemField name="items" onChange={this.props.onChange} rows={this.props.content.items} expanded={!this.state.reorder} />
				</div>
			</div>
		);
	}
}