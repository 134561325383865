import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import Listing from 'admin/components/Listing'
import Delete from 'admin/components/Delete'
import Input from 'core/components/form/Input'

class PostListing extends Component {
	render() {
		let columns = [
			{
				id: 'title',
				display: 'Title',
				value: item => {
					return <Link to={"/admin/dashboard/post/editor/" + item.id }>{item.title}</Link>
				}
			},
			{
				id: 'actions',
				display: '',
				value: item => {
					return <Delete onDelete={() => this.props.delete(item.id)} />
				}
			}
		];
		return (
			<div className="listing-wrapper">
				<div className="tab-nav">
					<span className="tab is-active">All Posts</span>
				</div>
				<div className="listing-actions">
					<Link className="button" to="/admin/dashboard/post/editor">Add New Post</Link>
					<div className="search-list">
						<Input type="search" name="search" label="Search" onInputChange={this.onSearchChange} />
						<button className="button" onClick={() => this.props.setSearch(this.state.search)}>Search</button>
					</div>
				</div>
				<div className="main-content">
					<Listing 
						columns={columns}
						
						{...this.props}
					/>
				</div>
			</div>
		)
	}
}

export default PostListing