import React, { Component } from 'react'
import EventBucket from 'frontend/modules/Event/components/EventBucket'

class EventListing extends Component {
	render() {
		return (
			<div className="event-list">
				{this.props.items.map((eventDate, i) => {
					return <EventBucket event={eventDate} />
				})}
			</div>
		)
	}
}

export default EventListing