import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import Textarea from 'core/components/form/Textarea'
import ImageUpload from 'core/components/form/ImageUpload'

export const fieldDefaults = {
	'title': '',
	'content': '',
	'image': {}
}

export class NewsletterSignup extends Component {
	render() {
		return (
			<div className="block">
				<p className="block-title">Newsletter Signup</p>
				<div className="block-fields">
					<Input type="text" name="title" label="Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.title} />
					<Textarea type="text" name="content" label="Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.content} />
					<ImageUpload name="image" onInputChange={this.props.onChange} value={this.props.content.image._id} />
				</div>
			</div>
		);
	}
}