import React, { Component } from "react";
import EventListing from "../components/EventListing";
import { getEvents, deleteEvent } from "../api";

class EventListingContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      page: 1,
      perPage: 10,
      orderBy: "title",
      orderDir: "asc",
      totalPages: 1,
      isLoading: false,
      status: ["active", "draft"],
      deleteSuccess: false,
      search: "",
    };

    this.onSearchChange = this.onSearchChange.bind(this);
    this.getEvents = this.getEvents.bind(this);
    this.setOrderBy = this.setOrderBy.bind(this);
    this.setPage = this.setPage.bind(this);
    this.delete = this.delete.bind(this);
  }

  onSearchChange(value) {
    this.setState({
      search: value,
    });
  }

  async getEvents(data) {
    this.setState({
      isLoading: true,
    });

    await getEvents(data)
      .then((response) => {
        this.setState({
          isLoading: false,
          totalPages: response.data.meta.last_page,
          items: response.data.data,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  setOrderBy(orderBy, orderDir) {
    this.setState({
      orderBy: orderBy,
      orderDir: orderDir,
    });
  }

  setPage(page) {
    this.setState({
      page: page,
    });
  }

  async delete(id) {
    this.setState({
      isLoading: true,
      deleteSuccess: false,
    });

    await deleteEvent(id)
      .then((response) => {
        this.setState({
          isLoading: false,
          deleteSuccess: true,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          deleteSuccess: false,
        });
      });
  }

  render() {
    return (
      <EventListing
        loadItems={this.getEvents}
        setOrderBy={this.setOrderBy}
        setPage={this.setPage}
        setSearch={this.onSearchChange}
        delete={this.delete}
        {...this.state}
      />
    );
  }
}

export default EventListingContainer;
