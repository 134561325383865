import React, { Component } from 'react'
import moment from 'moment'
import Loader from 'admin/components/Loader'

class Landing extends Component {
	render() {
		return (
			<div className="dashboard-landing">
				{this.props.showAudits ? 
					<div className="main-content latest-entries">
						<h2 className="table-title">Latest Entries</h2>
						{ 
							this.props.isLoading ? 
							<Loader />
							:
							<div className="model-list">
								<table>
									<thead>
										<tr>
											<th>Title</th>
											<th>Author</th>
											<th>Date</th>
										</tr>
									</thead>
									<tbody>
										{this.props.audits.map((audit, key) => 
											<tr key={key}>
												<td>{audit.message}</td>
												{ (audit.user !== null ? <td>{audit.user.firstname} {audit.user.lastname}</td> : <td></td>)}
												<td>{moment.utc(audit.created_at.date).local().format('MMMM D, YYYY - hh:mm a')}</td>
											</tr>
										)}
										
									</tbody>
								</table>
							</div>
						}
					</div>
					: '' }
			</div>
		)
	}
}

export default Landing;
