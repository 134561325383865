import React, {Component} from 'react'
import EventCalendarContainer from 'frontend/modules/Event/containers/EventCalendarContainer'
import EventListingContainer from 'frontend/modules/Event/containers/EventListingContainer'
import { exportICAL } from 'admin/modules/Event/api'

class Events extends Component {
	constructor(props) {
		super(props);

		this.state = {
			type: 'grid'
		}
	}

	async export() {
	    await exportICAL().then(response => {
	    	const url = window.URL.createObjectURL(new Blob([response.data]));
    	  const link = document.createElement('a');
    	  link.href = url;
    	  link.setAttribute('download', 'sasktel-events.ics');
    	  document.body.appendChild(link);
    	  link.click();
	    });
	}

	render() {
		return (
			<div className="block events-block">
				<div className="featured-menu-wrapper">
					<div className="featured-menu-wrapper-inner">
						<div className="event-menu">
							<nav className="menu featured-menu">
								<div className={"menu-item" + (this.state.type == 'calendar' ? ' active' : '')} onClick={() => this.setState({ type: 'calendar' })}>
									<button>Calendar</button>
								</div>
								<div className={"menu-item" + (this.state.type == 'grid' ? ' active' : '')} onClick={() => this.setState({ type: 'grid' })}>
									<button>Grid</button>
								</div>
								<div className={"menu-item" + (this.state.type == 'list' ? ' active' : '')} onClick={() => this.setState({ type: 'list' })}>
									<button>List</button>
								</div>
							</nav>
							<nav className="menu featured-menu">
								<div className="menu-item">
									<button onClick={() => this.export() }>iCal</button>
								</div>
								<div className="menu-item">
									<button onClick={() => window.print() }>Print</button>
								</div>
							</nav>
						</div>
					</div>
				</div> 
				<div className={"events-display " + this.state.type}>
					{this.state.type == 'calendar' ?
						<EventCalendarContainer /> : <EventListingContainer />
					}
				</div>
			</div>
		)
	}
}

export default Events