import React, { Component } from 'react';

class Modal extends Component {
	render() {
		return (
			<div className="modal-container">
				<div className={"modal-popup " + this.props.className}>
					<button className="close-modal" onClick={this.props.onClose}><i className="fa fa-times"></i></button>
					{(this.props.title) ? <p className="modal-title">{this.props.title}</p> : ''}
					<div className="modal-content">
						{this.props.children}
					</div>
				</div>
			</div>
		)
	}
}

export default Modal;