import React, { Component } from 'react'
import { connect } from 'react-redux'
import Edit from 'admin/components/EditForm'
import Create from 'admin/components/CreateForm'
import FormForm from '../components/FormForm'
import { getForm, saveForm, deleteForm, createForm } from '../actions'
import { getFormsErrors, getFormsFetching, getCurrentForm, getFormSaveSuccess, getFormDeleteSuccess } from '../selectors'
import FormWrapper from 'core/components/form/FormWrapper'

const fields = [
	'name',
	'notification_email'
]
const EditForm = FormWrapper(Edit, fields)
const CreateForm = FormWrapper(Create, fields)

class FormEditFormContainer extends Component {
	constructor(props) {
	    super(props);

		this.validate = this.validate.bind(this);
	}

	componentWillMount() {
		const formID = this.props.match.params.id;
		if(formID !== undefined)
			this.props.getForm(formID);
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.match.params.id !== nextProps.match.params.id){
			if(nextProps.match.params.id !== undefined)
				nextProps.getForm(nextProps.match.params.id);
		}
	}

	validate(name, label, value) {

		let errors = [];
		if(name === 'name' || name ==='notification_email') {
			if(value.length === 0) {
				errors.push(label + " is required.");
			}
		}

		return errors;
	}

	render() {
		return (
			<div>
				{
					this.props.match.params.id ?
					<EditForm
						id={this.props.match.params.id}
						getExisting={this.props.getForm}
						validate={this.validate} 
						name="Form"
						editValues={this.props.form}
						backLink="/admin/dashboard/forms"
						isLoading={this.props.isLoading}
						apiErrors={this.props.apiErrors}
						saveSuccess={this.props.saveSuccess}
						deleteSuccess={this.props.deleteSuccess}
						onSubmit={this.props.onSave}
						onDelete={this.props.onDelete}
						hideStatuses={true}
					>
						<FormForm />
					</EditForm>
					:
					<CreateForm 
						validate={this.validate} 
						name="Form"
						editValues={this.props.form}
						backLink="/admin/dashboard/forms"
						editorLink="/admin/dashboard/form/editor/"
						isLoading={this.props.isLoading}
						apiErrors={this.props.apiErrors}
						saveSuccess={this.props.saveSuccess}
						onSubmit={this.props.onCreate}
						hideStatuses={true}
					>
						<FormForm />
					</CreateForm>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		isLoading: getFormsFetching(state),
		apiErrors: getFormsErrors(state),
		form: getCurrentForm(state),
		saveSuccess: getFormSaveSuccess(state),
		deleteSuccess: getFormDeleteSuccess(state),
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		onSave: data => {
			dispatch(saveForm(props.match.params.id, data))
		},

		onCreate: data => {
			dispatch(createForm(data))
		},

		onDelete: data => {
			dispatch(deleteForm(props.match.params.id))
		},

		getForm: id => {
			dispatch(getForm(id))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FormEditFormContainer)