import React, { Component } from 'react'
import API from 'API'
import ContactForm from 'frontend/modules/Page/components/forms/ContactForm'
import MenuContainer from 'frontend/modules/Menu/containers/MenuContainer'

class Contact extends Component {
	constructor(props) {
		super(props);

		this.state = {
	    	isLoading: false,
	    	errors: [],
	    	showConfirmation: false
	    }

		this.onSubmit = this.onSubmit.bind(this);
	}

	async onSubmit(id, values, labels) {
		let labelledValues = {};
		for (var name in values) {
			let value = values[name];
			let label = name;
			if(labels[name] !== undefined)
				label = labels[name];

			labelledValues[label] = value;
		}

	    let theAPI = new API();

		this.setState({
			isLoading: true
		});

	    await theAPI.submitForm(id, { values: JSON.stringify(labelledValues) }).then()
			.then(response => {
				this.setState({
					isLoading: false,
					showConfirmation: true
				});
				// console.log(response)
				// let confirmationID = response.data.confirmation_id;
				// this.props.history.push('/thank-you/' + confirmationID);
            }).catch(error => {
            	if(error.response && error.response.status === 422) {
            		this.setState({ errors: error.response.data.errors});
            	} 
            	this.setState({
					isLoading: false
				});
            });
	}

	render() {
		const { box_office_title, box_office_content, social_title, hours, details } = this.props.content;

		return (
			<div className="block contact-block">
				<div className="block-inner">
					<div className="block-left">
						<div className="box-office contact-section">
							{ box_office_title !== '' ? <p class="contact-section-title">{ box_office_title }</p> : ''}
							{ box_office_content !== '' ? <p class="contact-section-content">{ box_office_content }</p> : ''}
							<div className="hours">
								{ hours.map((hour, i) => {
									let { label, detail } = hour;

									return (
										<div className="hours-item">
											{ label !== '' ? <p class="hours-label">{ label }</p> : ''}
											{ detail !== '' ? <p class="hours-detail">{ detail }</p> : ''}
										</div>
									)
								})}
							</div>
						</div>
						<div className="details contact-section">
							{ details.map((detail, i) => {
								let { title, content } = detail;

								return (
									<div className="details-item">
										{ title !== '' ? <p class="contact-section-title">{ title }</p> : ''}
										{ content !== '' ? <p class="contact-section-content">{ content }</p> : ''}
									</div>
								)
							})}
							<div className="details-item">
								{ social_title !== '' ? <p class="contact-section-title">{ social_title }</p> : ''}
								<MenuContainer id={5} />
							</div>
						</div>
					</div>
					<div className="block-right">
						{ this.state.showConfirmation ? 
							<p class="form-confirmation">Thank you for your submission.</p>
							:
							<ContactForm isLoading={this.state.isLoading} apiErrors={this.state.errors} onSubmit={this.onSubmit} />
						}
					</div>
				</div>
			</div>
		)
	}
}

export default Contact