import React, { Component } from 'react'
import { connect } from 'react-redux'
import Edit from 'admin/components/EditForm'
import Create from 'admin/components/CreateForm'
import MenuForm from '../components/MenuForm'
import { getMenu, saveMenu, deleteMenu, createMenu } from '../actions'
import { getMenusErrors, getMenusFetching, getCurrentMenu, getMenuSaveSuccess, getMenuDeleteSuccess } from '../selectors'
import FormWrapper from 'core/components/form/FormWrapper'

const fields = [
	'name', 
	'class',
	{
		name: 'items',
		default: []
	}
]
const EditForm = FormWrapper(Edit, fields)
const CreateForm = FormWrapper(Create, fields)

class MenuEditFormContainer extends Component {
	constructor(props) {
	    super(props);

		this.validate = this.validate.bind(this);
	}

	componentWillMount() {
		const menuID = this.props.match.params.id;
		if(menuID !== undefined)
			this.props.getMenu(menuID);
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.match.params.id !== nextProps.match.params.id){
			if(nextProps.match.params.id !== undefined)
				nextProps.getMenu(nextProps.match.params.id);
		}
	}

	validate(name, label, value) {

		let errors = [];
		if(name === 'name') {
			if(value.length === 0) {
				errors.push(label + " is required.");
			}
		}

		return errors;
	}

	render() {
		return (
			<div>
				{
					this.props.match.params.id ?
					<EditForm
						id={this.props.match.params.id}
						getExisting={this.props.getMenu}
						validate={this.validate} 
						name="Menu"
						editValues={this.props.menu}
						backLink="/admin/dashboard/menus"
						isLoading={this.props.isLoading}
						apiErrors={this.props.apiErrors}
						saveSuccess={this.props.saveSuccess}
						deleteSuccess={this.props.deleteSuccess}
						onSubmit={this.props.onSave}
						onDelete={this.props.onDelete}
						hideStatuses={true}
					>
						<MenuForm />
					</EditForm>
					:
					<CreateForm 
						validate={this.validate} 
						name="Menu"
						editValues={this.props.menu}
						backLink="/admin/dashboard/menus"
						editorLink="/admin/dashboard/menu/editor/"
						isLoading={this.props.isLoading}
						apiErrors={this.props.apiErrors}
						saveSuccess={this.props.saveSuccess}
						onSubmit={this.props.onCreate}
						hideStatuses={true}
					>
						<MenuForm />
					</CreateForm>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		isLoading: getMenusFetching(state),
		apiErrors: getMenusErrors(state),
		menu: getCurrentMenu(state),
		saveSuccess: getMenuSaveSuccess(state),
		deleteSuccess: getMenuDeleteSuccess(state),
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		onSave: data => {
			dispatch(saveMenu(props.match.params.id, data))
		},

		onCreate: data => {
			dispatch(createMenu(data))
		},

		onDelete: data => {
			dispatch(deleteMenu(props.match.params.id))
		},

		getMenu: id => {
			dispatch(getMenu(id))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuEditFormContainer)