import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchPages, setPagesOrder, setPagesPage, deletePage, setPagesSearch } from '../actions'
import { getPages, getPagesPage, getPagesPerPage, getPagesOrderBy, getPagesOrderDir, getPagesTotalPages, getPagesFetching, getPagesStatus, getPageDeleteSuccess, getPagesSearch } from '../selectors'
import Listing from 'admin/components/Listing'
import Delete from 'admin/components/Delete'
import Input from 'core/components/form/Input'

class PageListingContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			search: ''
		}

		this.onSearchChange = this.onSearchChange.bind(this)
	}

	onSearchChange(name, value) {
		this.setState({
			search: value
		})
	}

	render() {
		let columns = [
			{
				id: 'title',
				display: 'Title',
				value: item => {
					return <Link to={"/admin/dashboard/page/editor/" + item.id }>{item.title}</Link>
				}
			},
			{
				id: 'slug',
				display: 'Slug'
			},
			{
				id: 'status',
				display: 'Status'
			},
			{
				id: 'actions',
				display: '',
				value: item => {
					return <Delete onDelete={() => this.props.delete(item.id)} />
				}
			}
		];
		return (
			<div className="listing-wrapper">
				<div className="tab-nav">
					<span className="tab is-active">All Pages</span>
				</div>
				<div className="listing-actions">
					<Link className="button" to="/admin/dashboard/page/editor">Add New Page</Link>
					<div className="search-list">
						<Input type="search" name="search" label="Search" onInputChange={this.onSearchChange} />
						<button className="button" onClick={() => this.props.setSearch(this.state.search)}>Search</button>
					</div>
				</div>
				<div className="main-content">
					<Listing 
						columns={columns}
						{...this.props}
					/>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state = {}) => {
    return {
    	items: getPages(state),
    	page: getPagesPage(state),
    	perPage: getPagesPerPage(state),
    	orderBy: getPagesOrderBy(state),
    	orderDir: getPagesOrderDir(state),
    	totalPages: getPagesTotalPages(state),
    	isLoading: getPagesFetching(state),
    	status: getPagesStatus(state),
    	deleteSuccess: getPageDeleteSuccess(state),
    	search: getPagesSearch(state)
    }
};

const mapDispatchToProps = dispatch => {
	return {
		loadItems: data => {
			dispatch(fetchPages(data))
		},

		setOrderBy: (orderBy, orderDir) => {
			dispatch(setPagesOrder(orderBy, orderDir))
		},

		setPage: page => {
			dispatch(setPagesPage(page))
		},

		setSearch: search => {
			dispatch(setPagesSearch(search))
		},

		delete: id => {
			dispatch(deletePage(id))
		}
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(PageListingContainer)