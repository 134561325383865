import React, { Component } from 'react'
import {withRouter} from 'react-router-dom';
import Post from '../components/Post'
import { getPostBySlug, getActivePosts } from 'admin/modules/Post/api'

class PostContainer extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
	    	featuredImage: {},
	    	title: '',
	    	content: '',
	    	created_at: '',
	    	otherPosts: [],
	    	otherPostsLoading: false
	    }
	}

	async getPost(slug) {
		if(slug === undefined)
			return false;

	    await getPostBySlug(slug)
	    	.then(post => {
	    		let postData = post.data.data

	    		document.title = 'SaskTel Centre - ' + postData.title;

	    		this.setState({
	    			featuredImage: postData.featuredimage,
	    			title: postData.title,
	    			content: postData.content,
	    			created_at: postData.createdAt
	    		});
	    	})
	    	.catch(error => {
		    	if(error.response.status === 404)
		    		this.props.history.push('/404');
		    		return;
	    	}) 
	}

	async loadOtherPosts() {
		this.setState({
			otherPostsLoading: true
		})

		let data = {
			page: 1,
			perPage: 4,
			orderBy: 'created_at',
			orderDir: 'desc'
		}

	    await getActivePosts(data)
	    	.then(response => {
	    		this.setState({
					otherPostsLoading: false,
					otherPosts: response.data.data,
				})
	    	})
	    	.catch(error => {
		    	this.setState({
					otherPostsLoading: false
				})
	    	}) 
	}

	componentDidMount() {
		let path = this.props.match.params.slug;
		if(path !== undefined) {
			path = path.replace(/(^\/)|(\/$)/g, "")
			this.getPost(path);
			this.loadOtherPosts();
		}
	}

	render() {
		const { featuredImage, title, content, created_at, otherPosts, otherPostsLoading } = this.state

		return (
			<Post featuredImage={featuredImage} title={title} content={content} createdDate={created_at} otherPosts={otherPosts} otherPostsLoading={otherPostsLoading} />
		)
	}
}

export default withRouter(PostContainer);