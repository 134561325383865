import React, { Component } from 'react'
import MenuContainer from 'frontend/modules/Menu/containers/MenuContainer'
import InstaGrid from './block-parts/InstaGrid'

class InstagramFeed extends Component {
	render() {
		const { title, nav_title } = this.props.content;

		return (
			<div className="block instagram-feed">
				<div className="block-header">
					{ title !== '' ? <h2 class="block-title">{ title }</h2> : '' }
					<div className="social">
						{ nav_title !== '' ? <p>{nav_title}</p> : '' }
						<MenuContainer id={5} />
					</div>
				</div>
				<InstaGrid account="sasktelcentre" numberOfMediaElements={8} />
			</div>
		)
	}
}

export default InstagramFeed