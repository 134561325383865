import React, { Component } from 'react'

class AccordionItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			height: 0
		}

		this.myRef = React.createRef();
	}

	componentDidMount() {
	    const height = this.myRef.current.clientHeight;
	    this.setState({ height });
	}

	render() {
		let styles = {};
		if(this.state.open) {
			styles = {
				maxHeight: this.state.height
			}
		}

		return (
			<li className={"accordion-item" + (this.state.open ? ' open' : '')}>
				<p className="accordion-title" onClick={() => this.setState({ open: !this.state.open })}>{ this.props.title }</p>
				<div className="accordion-content" style={styles}>
					<div className="accordion-content-inner" ref={this.myRef} dangerouslySetInnerHTML={{__html: this.props.content}}></div>
				</div>
			</li>
		)
	}
}

export default AccordionItem