import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import Textarea from 'core/components/form/Textarea'
import FormWrapper from 'core/components/form/FormWrapper'
import Loader from 'admin/components/Loader'
import Dropdown from 'frontend/components/Dropdown'

const fields = [
	'type',
	'fullname',
	'message',
	'phone_number',
	'email_address'
];

class ContactForm extends Component {

	render() {
		const { values, errors } = this.props
		let options = ['General Inquiries','Venue & Ice Rentals','Box Office & Ticketing','Luxury Suite Rentals','Venue & Event Partnerships','Accounting Department','Saskatoon Blades Hockey Club','Saskatchewan Rush','Saskatchewan Rattlers']

		return (
			<form onSubmit={this.props.onSubmit}>
				<p className="form-title">Ask Us Anything</p>
				<fieldset>
					<Dropdown items={options} selected={values.type == '' ? 0 : options.indexOf(values.type)} onChange={(index) => this.props.onInputChange('type', options[index])} />
					<Input type="text" name="fullname" label="Full Name" placeholder="Your Name" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.fullname} errors={errors.fullname} />
					<Input type="text" name="phone_number" label="Phone Number" placeholder="Your Phone Number" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.phone_number} errors={errors.phone_number} />
					<Input type="text" name="email_address" label="Email Address" placeholder="Your Email Address" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.email_address} errors={errors.email_address} />
					<Textarea name="message" label="Message" placeholder="Your Message" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.message} errors={errors.message} />
					<button type="submit" className={"button solid " + (this.props.isLoading ? ' loading' : '')} disabled={this.props.isDisabled}>
							{
								this.props.isLoading ?
								<Loader/>
								:
								<span>Send</span>
							}
						</button>
				</fieldset>
			</form>
		)
	}
}

const Form = FormWrapper(ContactForm, fields)

class ContactFormWrapper extends Component {
	constructor(props) {
	    super(props);

		this.validate = this.validate.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(values) {
		const labels = {
			'fullname': 'Full Name',
			'message': 'Message',
			'phone_number': 'Phone Number',
			'email_address': 'Email Address'
		};
		
		this.props.onSubmit(1, values, labels);
	}

	validate(name, label, value) {

		let errors = [];
		if(name === 'fullname' || name === 'message' || name === 'email_address') {
			if(value === undefined || value.length === 0) {
				errors.push(label + " is required.");
			}
		}

		return errors;
	}

	render() {
		return <Form isLoading={this.props.isLoading} apiErrors={this.props.apiErrors} validate={this.validate} onSubmit={this.onSubmit} />
	}
}

export default ContactFormWrapper;