import React, { Component } from 'react';

export const fieldDefaults = {}

export class News extends Component {
	render() {
		return (
			<div className="block">
				<p className="block-title">News</p>
				
			</div>
		);
	}
}