import React, { Component } from 'react'

class NewsletterSignup extends Component {
	render() {
		let { title, content, image } = this.props.content;

		return (
			<div className="block newsletter-signup" style={{ backgroundImage: 'url(' + image.thumbnailUrl + '?w=1920&h=500&fit=crop)'}}>
				<div className="block-inner">
					{ title !== '' ? <h2 className="block-title">{ title }</h2> : ''}
					{ content !== '' ? <div className="block-content">{ content }</div> : ''}
					<div id="mc_embed_signup">
						<form action="https://sasktelcentre.us1.list-manage.com/subscribe/post?u=fc6ed7adda090af83c4ddf288&amp;id=b7be620496" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
						    <div id="mc_embed_signup_scroll">
								<div className="mc-field-group">
									<input type="email" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="Email Address" />
								</div>
								<div className="mc-field-group">
									<input type="text" name="FNAME" className="" id="mce-FNAME" placeholder="First Name" />
								</div>
								<div className="mc-field-group">
									<input type="text" name="LNAME" className="" id="mce-LNAME" placeholder="Last Name" />
								</div>
								<div id="mce-responses" className="clear">
									<div className="response" id="mce-error-response" style={{display: 'none'}}></div>
									<div className="response" id="mce-success-response" style={{display: 'none'}}></div>
								</div>    
							    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_fc6ed7adda090af83c4ddf288_b7be620496" tabindex="-1" value=""/></div>
							    <div className="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button"/></div>
						    </div>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default NewsletterSignup