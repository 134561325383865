import React, { Component } from 'react'
import { default as Footer } from 'frontend/containers/layout/FooterContainer';
import MenuContainer from 'frontend/modules/Menu/containers/MenuContainer'

import {Helmet} from "react-helmet";

import Accordion from './blocks/Accordion'
import Contact from './blocks/Contact'
import ContentOnImage from './blocks/ContentOnImage'
import ContentWithBuckets from './blocks/ContentWithBuckets'
import CTA from './blocks/CTA'
import Directors from './blocks/Directors'
import Events from './blocks/Events'
import FeatureBuckets from './blocks/FeatureBuckets'
import FeaturedEvents from './blocks/FeaturedEvents'
import FiftyFifty from './blocks/FiftyFifty'
import ImageSelector from './blocks/ImageSelector'
import ImageText from './blocks/ImageText'
import ImageWithContentBuckets from './blocks/ImageWithContentBuckets'
import InstagramFeed from './blocks/InstagramFeed'
import Map from './blocks/Map'
import News from './blocks/News'
import NewsletterSignup from './blocks/NewsletterSignup'
import SlideshowWithContent from './blocks/SlideshowWithContent'
import SquareImageBuckets from './blocks/SquareImageBuckets'
import TwoColumnContent from './blocks/TwoColumnContent'
import TwoColumnOnImage from './blocks/TwoColumnOnImage'
import UpcomingEvents from './blocks/UpcomingEvents'

class Page extends Component {
	components = {
		'accordion': Accordion,
		'contact': Contact,
		'content-on-image': ContentOnImage,
		'content-with-buckets': ContentWithBuckets,
		'cta': CTA,
		'directors': Directors,
		'events': Events,
		'feature-buckets': FeatureBuckets,
		'featured-events': FeaturedEvents,
		'fifty-fifty': FiftyFifty,
		'image-selector': ImageSelector,
		'image-text': ImageText,
		'image-with-content-buckets': ImageWithContentBuckets,
		'instagram-feed': InstagramFeed,
		'map': Map,
		'news': News,
		'newsletter-signup': NewsletterSignup,
		'slideshow-with-content': SlideshowWithContent,
		'square-image-buckets': SquareImageBuckets,
		'two-column-content': TwoColumnContent,
		'two-column-on-image': TwoColumnOnImage,
		'upcoming-events': UpcomingEvents
	};

	render() {
		return (
			<div className={"page-main " + this.props.background + ( this.props.customFields !== null && this.props.customFields.length !== 0 && this.props.customFields.featured_title !== '' ? ' has-top-content' : '' ) + ( this.props.customFields !== null && this.props.customFields.extra_block_padding ? ' extra-block-padding' : '' )}>
				<Helmet>
		            <title>SaskTel Centre - { this.props.title }</title>
		            <meta name="description" content={this.props.metaDescription} />
		        </Helmet>
				<div className="featured-image" style={{ backgroundImage: 'url(' + this.props.featuredImage.thumbnailUrl + '?w=1920)'}}>
					<div className="featured-content">
						{ this.props.customFields !== null && this.props.customFields.featured_title !== '' ? <h1 className="featured-title">{ this.props.customFields.featured_title}</h1> : ''}
						{ 
							this.props.customFields !== null && this.props.customFields.featured_menu !== undefined ? 
								<div className="featured-menu-wrapper">
									<div className="featured-menu-wrapper-inner">
										<MenuContainer id={this.props.customFields.featured_menu} className="featured-menu" />
									</div>
								</div> 
							: ''
						}
						{ 
							this.props.customFields !== null && this.props.customFields.theme === 'fifty-fifty' ? 
							<div className="fifty-fifty-featured">
								<div className="fifty-fifty-amount-wrapper">
									{ this.props.customFields.fifty_fifty_pretext !== '' ? <p className="fifty-fifty-pre">{ this.props.customFields.fifty_fifty_pretext }</p> : '' }
									{ this.props.customFields.fifty_fifty_value !== '' ? <p className="fifty-fifty-value">{ this.props.customFields.fifty_fifty_value }</p> : '' }
									{ this.props.customFields.fifty_fifty_posttext !== '' ? <p className="fifty-fifty-post">{ this.props.customFields.fifty_fifty_posttext }</p> : '' }
								</div>
								{ this.props.customFields.fifty_fifty_title !== '' ? <h2 className="fifty-fifty-title">{ this.props.customFields.fifty_fifty_title }</h2> : '' }
							</div>
							: ''
						}
						{ 
							this.props.customFields !== null && this.props.customFields.theme === 'premium-hospitality' ? 
							<div className="premium-hospitality-featured">
								<div className="premium-featured-inner">
									{ this.props.customFields.premium_content !== '' ? <div className="premium-content" dangerouslySetInnerHTML={{__html: this.props.customFields.premium_content}}></div> : '' }
									<div className="button-group">
										{this.props.customFields.premium_link_one_url !== "" ? <a className="button" href={this.props.customFields.premium_link_one_url}>{ this.props.customFields.premium_link_one_text }</a> : ''}
										{this.props.customFields.premium_link_two_url !== "" ? <a className="button" href={this.props.customFields.premium_link_two_url}>{ this.props.customFields.premium_link_two_text }</a> : ''}
									</div>
								</div>
							</div>
							: ''
						}
						{ 
							this.props.customFields !== null && this.props.customFields.theme === 'about' ? 
							<div className="about-featured">
								<div className="about-featured-inner">
									<div className="about-featured-main">
										{ this.props.customFields.about_title !== '' ? <h2 className="about-title">{ this.props.customFields.about_title }</h2> : '' }
										{ this.props.customFields.about_content !== '' ? <div className="about-content" dangerouslySetInnerHTML={{__html: this.props.customFields.about_content}}></div> : '' }
									</div>
									{ this.props.customFields.about_image_label !== '' ? <p className="about-image-label">{ this.props.customFields.about_image_label }</p> : '' }
								</div>
							</div>
							: ''
						}
					</div>
				</div>
				<div className="curved">
					<div className="curved-inner">
						<div className="blocks">
							{this.props.blocks.map((block, i) => {
								let BlockName = this.components[block.type];
								return <BlockName key={i} content={JSON.parse(block.content)} />
							})}
						</div>
						<Footer />
					</div>
				</div>
			</div>
		)
	}
}

export default Page