import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import Textarea from 'core/components/form/Textarea'
import ImageUpload from 'core/components/form/ImageUpload'
import SingleImageUpload from 'core/components/form/SingleImageUpload'
import MultiSelect from 'core/components/form/MultiSelect'

class UserForm extends Component {
	render() {
		const { values, errors } = this.props
		return (
			<div className="main-content">
				<form className="login-form" onSubmit={this.props.onSubmit}>
					<p className="form-title">User Profile</p>
					<fieldset>
					{ 
						this.props.singleImage ? 
						<SingleImageUpload name="profileimage" onInputChange={this.props.onInputChange} errors={errors.profileimage} value={values.profileimage} />
						:
						<ImageUpload name="profileimage" onInputChange={(name, value) => this.props.onInputChange(name, value._id)} errors={errors.profileimage} value={values.profileimage} />
					}
					</fieldset>
					<fieldset>
						<p className="form-title">Account Information</p>
						<Input type="text" name="firstname" label="First Name" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.firstname} errors={errors.firstname} />
						<Input type="text" name="lastname" label="Last Name" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.lastname} errors={errors.lastname} />
						<Input type="text" name="email" label="Email Address" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.email} errors={errors.email} />
					</fieldset>
					<fieldset>
						<p className="form-title">Change Password</p>
						<Input type="password" name="current_password" label="Current Password" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.current_password} errors={errors.current_password} />
						<Input type="password" name="password" label="Password" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.password} errors={errors.password} />
						<Input type="password" name="password_confirmation" label="Confirm Password" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.password_confirmation} errors={errors.password_confirmation} />
					</fieldset>
					{this.props.showRoles ?
						<fieldset>
							<p className="form-title">Roles</p>
							<MultiSelect items={this.props.roles} selected={values.roles} onChange={this.props.onInputChange} name="roles" />
						</fieldset>
						: ''
					}
				</form>
			</div>
		)
	}
}

export default UserForm;