import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import Textarea from 'core/components/form/Textarea'
import MultiRowWrapper from 'core/components/form/MultiRowWrapper'

export const fieldDefaults = {
	'box_office_title': '',
	'box_office_content': '',
	'hours': [],
	'details': [],
	'social_title': ''
}

class HoursForm extends Component {
	render() {
		return (
			<div className="repeater">
				
				{this.props.rows.map((hoursItem, i) => {
					return (
						<div className="repeater-section" key={i}>
							<Input type="text" name="label" label="Label" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={hoursItem.label} />
							<Input type="text" name="detail" label="Detail" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={hoursItem.detail} />
							
							<button type="button" className="remove" onClick={() => this.props.removeRow(i)}></button>
						</div>
					)
				})}
				<button type="button" className="add" onClick={() => this.props.addRow()}>
					Add Hours <span>+</span>
				</button>
			</div>
		)
	}
} 

const HoursField = MultiRowWrapper(HoursForm, {
        label: '',
        detail: ''
    });

class DetailsForm extends Component {
	render() {
		return (
			<div className="repeater">
				
				{this.props.rows.map((hoursItem, i) => {
					return (
						<div className="repeater-section" key={i}>
							<Input type="text" name="title" label="Title" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={hoursItem.title} />
							<Textarea name="content" label="Content" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={hoursItem.content} />
							
							<button type="button" className="remove" onClick={() => this.props.removeRow(i)}></button>
						</div>
					)
				})}
				<button type="button" className="add" onClick={() => this.props.addRow()}>
					Add Details <span>+</span>
				</button>
			</div>
		)
	}
} 

const DetailsField = MultiRowWrapper(DetailsForm, {
        title: '',
        content: ''
    });

export class Contact extends Component {
	render() {
		return (
			<div className="block">
				<p className="block-title">Contact</p>
				<div className="block-fields">
					<Input type="text" name="box_office_title" label="Box Office Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.box_office_title} />
					<Textarea name="box_office_content" label="Box Office Content" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.box_office_content} />
					<HoursField name="hours" onChange={this.props.onChange} rows={this.props.content.hours} />
					<DetailsField name="details" onChange={this.props.onChange} rows={this.props.content.details} />
					<Input type="text" name="social_title" label="Social Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.social_title} />
				</div>
			</div>
		);
	}
}