import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import Select from 'core/components/form/Select'
import DatePicker from 'core/components/form/DatePicker'
import MultiRowWrapper from 'core/components/form/MultiRowWrapper'

export const fieldDefaults = {
	'items': []
}

class FiftyFiftyItemForm extends Component {
	render() {
		return (
			<div className="repeater">
				
				{this.props.rows.map((item, i) => {
					return (
						<div className="repeater-section" key={i}>
							<DatePicker name="date" label="Date" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={item.date} />
							<Input type="text" name="time" label="Time" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={item.time} />
							<Input type="text" name="title" label="Title" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={item.title} />
							<Input type="text" name="winning_number" label="Winning Number" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={item.winning_number} />
							<Input type="text" name="amount" label="Amount" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={item.amount} />
							<Select name="status" label="Status" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={item.status}>
								<option value="unclaimed">Unclaimed</option>
								<option value="claimed">Claimed</option>
							</Select>
							
							<button type="button" className="remove" onClick={() => this.props.removeRow(i)}></button>
						</div>
					)
				})}
				<button type="button" className="add" onClick={() => this.props.addRow()}>
					Add Fifty Fifty Item <span>+</span>
				</button>
			</div>
		)
	}
} 

const FiftyFiftyItemField = MultiRowWrapper(FiftyFiftyItemForm, {
		date: '',
        time: '',
        title: '',
        winning_number: '',
        amount: '',
        status: 'unclaimed'
    });

export class FiftyFifty extends Component {
	render() {
		return (
			<div className="block">
				<p className="block-title">50/50</p>
				<div className="block-fields">
					<FiftyFiftyItemField name="items" onChange={this.props.onChange} rows={this.props.content.items} />
				</div>
			</div>
		);
	}
}