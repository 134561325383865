import React, { Component } from 'react';
import PageBlocks from './PageBlocks'
import Input from 'core/components/form/Input'
import Textarea from 'core/components/form/Textarea'
import Select from 'core/components/form/Select'
import Checkbox from 'core/components/form/Checkbox'
import ImageUpload from 'core/components/form/ImageUpload'
import MultiRowWrapper from 'core/components/form/MultiRowWrapper'
import WYSIWYG from 'core/components/form/WYSIWYG'

import Page from 'frontend/modules/Page/components/Page'

const PageBlocksField = MultiRowWrapper(PageBlocks, {
        type: '',
        content: {}
    });

class PageForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			featuredimage: null
		}
	}

	setImageState(name, value) {
		this.setState({
			[name]: value
		})
		this.props.onInputChange(name, value._id)
	}

	render() {
		const { values, errors } = this.props

		return (
			<div className="main-content">
				<form className="login-form" onSubmit={this.props.onSubmit}>
					<fieldset>
						<p className="form-title">Page</p>
						<div className="field-group">
							<Input type="text" name="title" label="Title" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.title} errors={errors.title} />
							<Input type="text" name="slug" label="Slug" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.slug} errors={errors.slug} />
						</div>
						<Textarea name="meta_description" label="Meta Description" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.meta_description} errors={errors.meta_description} />
						<Select name="background" label="Background" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.background} errors={errors.background}>
							<option value=""> -- Default -- </option>
							<option value="dark-primary">Dark Blue</option>
							<option value="dark-secondary">Purple</option>
							<option value="third-color">Light Purple</option>
						</Select>
						<ImageUpload name="featuredimage" onInputChange={(name, value) => this.setImageState(name, value)} errors={errors.featuredimage} value={values.featuredimage} />
					</fieldset>
					<fieldset>
						<p className="form-title">Featured</p>
						<Input type="text" name="featured_title" label="Featured Title" onInputChange={(name, value) => this.props.onInputChange('custom_fields', { ...values.custom_fields, [name]: value})} removeFocus={this.props.onRemoveFocus} value={values.custom_fields.featured_title} errors={errors.featured_title} />
						<Select name="featured_menu" label="Menu" onInputChange={(name, value) => this.props.onInputChange('custom_fields', { ...values.custom_fields, [name]: value})} value={values.custom_fields.featured_menu}>
							<option value=""> -- Select a Menu -- </option>
						{this.props.menus.map((menu, i) => {
							return <option value={menu.id}>{menu.name}</option>
						})}
						</Select>
						<Checkbox type="checkbox" name="extra_block_padding" label="Extra Block Padding" onInputChange={(name, value) => this.props.onInputChange('custom_fields', { ...values.custom_fields, [name]: value})} value={values.custom_fields.extra_block_padding} />
					</fieldset>
					<fieldset>
						<p className="form-title">Theme</p>
						<Select name="theme" label="Theme" onInputChange={(name, value) => this.props.onInputChange('custom_fields', { ...values.custom_fields, [name]: value})} value={values.custom_fields.theme}>
							<option value=""> -- Default Theme -- </option>
							<option value="fifty-fifty">50/50</option>
							<option value="premium-hospitality">Premium Hospitality</option>
							<option value="about">About SaskTel Centre</option>
						</Select>
					</fieldset>
					{ values.custom_fields.theme == 'fifty-fifty' ? 
						<fieldset>
							<p className="form-title">50/50 Fields</p>
							<Input type="text" name="fifty_fifty_value" label="50/50 Value" onInputChange={(name, value) => this.props.onInputChange('custom_fields', { ...values.custom_fields, [name]: value})} value={values.custom_fields.fifty_fifty_value} />
							<Input type="text" name="fifty_fifty_pretext" label="50/50 Pre Text" onInputChange={(name, value) => this.props.onInputChange('custom_fields', { ...values.custom_fields, [name]: value})} value={values.custom_fields.fifty_fifty_pretext} />
							<Input type="text" name="fifty_fifty_posttext" label="50/50 Post Text" onInputChange={(name, value) => this.props.onInputChange('custom_fields', { ...values.custom_fields, [name]: value})} value={values.custom_fields.fifty_fifty_posttext} />
							<Input type="text" name="fifty_fifty_title" label="50/50 Title" onInputChange={(name, value) => this.props.onInputChange('custom_fields', { ...values.custom_fields, [name]: value})} value={values.custom_fields.fifty_fifty_title} />
						</fieldset>
					: '' }
					{ values.custom_fields.theme == 'premium-hospitality' ? 
						<fieldset>
							<p className="form-title">Premium Hospitality Fields</p>
							<WYSIWYG name="premium_content" label="Content" onInputChange={(name, value) => this.props.onInputChange('custom_fields', { ...values.custom_fields, [name]: value})} value={values.custom_fields.premium_content} />
							<div className="field-group">
								<Input type="text" name="premium_link_one_text" label="First Link Text" onInputChange={(name, value) => this.props.onInputChange('custom_fields', { ...values.custom_fields, [name]: value})} value={values.custom_fields.premium_link_one_text} />
								<Input type="text" name="premium_link_one_url" label="First Link URL" onInputChange={(name, value) => this.props.onInputChange('custom_fields', { ...values.custom_fields, [name]: value})} value={values.custom_fields.premium_link_one_url} />
							</div>
							<div className="field-group">
								<Input type="text" name="premium_link_two_text" label="Second Link Text" onInputChange={(name, value) => this.props.onInputChange('custom_fields', { ...values.custom_fields, [name]: value})} value={values.custom_fields.premium_link_two_text} />
								<Input type="text" name="premium_link_two_url" label="Second Link URL" onInputChange={(name, value) => this.props.onInputChange('custom_fields', { ...values.custom_fields, [name]: value})} value={values.custom_fields.premium_link_two_url} />
							</div>
						</fieldset>
					: '' }
					{ values.custom_fields.theme == 'about' ? 
						<fieldset>
							<p className="form-title">About SaskTel Centre Fields</p>
							<Input type="text" name="about_title" label="Title" onInputChange={(name, value) => this.props.onInputChange('custom_fields', { ...values.custom_fields, [name]: value})} value={values.custom_fields.about_title} />
							<WYSIWYG name="about_content" label="Content" onInputChange={(name, value) => this.props.onInputChange('custom_fields', { ...values.custom_fields, [name]: value})} value={values.custom_fields.about_content} />
							<Input type="text" name="about_image_label" label="Image Label" onInputChange={(name, value) => this.props.onInputChange('custom_fields', { ...values.custom_fields, [name]: value})} value={values.custom_fields.about_image_label} />
						</fieldset>
					: '' }
					<fieldset>
						<p className="form-title">Blocks</p>
						<PageBlocksField name="blocks" onChange={this.props.onInputChange} rows={values.blocks} errors={errors.blocks} />
					</fieldset>
				</form>
				{ 
					this.props.preview ? 
					<div className="page-preview-wrapper">
						<Page featuredImage={this.state.featuredimage} title={values.title} background={values.background} blocks={values.blocks} customFields={values.custom_fields} />
						<button className="button close-preview-button" onClick={this.props.togglePreview}>Close Preview</button>
					</div>
					: ''
				}
			</div>
		)
	}
}

export default PageForm;