import React, { Component } from 'react'
import moment from 'moment'
import TinySlider from "tiny-slider-react";
import { getEventDatesByIDs } from 'admin/modules/Event/api'

class FeaturedEvents extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
	    	events: []
	    }
	}

	async getEvents() {
		const { events } = this.props.content;
		let eventIDs = [];
		events.forEach(event => eventIDs.push(event.event_id))

	    const fetchedEvents = await getEventDatesByIDs({
	    	'ids': eventIDs
	    });

	    let eventData = fetchedEvents.data.data

	    this.setState({
	    	events: eventData
	    });
	}

	componentDidMount() {
		this.getEvents();
	}

	getEventFromState(id) {
		return this.state.events.find(event => event.id == id)
	}

	render() {
		const { events } = this.props.content;
		const settings = {
		  lazyload: true,
		  mouseDrag: true
		};
		
		return (
			<div className="block featured-events">
				<TinySlider settings={settings}>
					{events.map((eventID, i) => {
						let eventDate = this.getEventFromState(parseInt(eventID.event_id))
						if(!eventDate)
							return false;
						let event = eventDate.event
						const truncatedContent = event.content.length > 150 ? event.content.substring(0, 150) + '...' : event.content;
						return (
							<div>
								<div className="slide">
									{event.featuredimage !== "" ? <div className="event-image" style={{ backgroundImage: 'url(' + event.featuredimage.thumbnailUrl + '?w=1000)'}}></div> : ''}
									<div className="event-main">
										<div className="event-date">{ moment(eventDate.start).format('MMMM D, YYYY | h:mmA') }</div>
										{event.title !== "" ? <h2 className="event-title">{event.title}</h2> : ''}
										{event.content !== "" ? <div className="event-content" dangerouslySetInnerHTML={{__html: truncatedContent}}></div> : ''}
										<div className="button-group">
											<a className="event-link button primary" href={'events/' + event.slug}>More Info</a>
											{eventDate.tickets_url !== "" ? <a className="event-link button secondary" href={eventDate.tickets_url}>Buy Tickets</a> : ''}
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</TinySlider>
			</div>
		)
	}
}

export default FeaturedEvents