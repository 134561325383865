import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import Textarea from 'core/components/form/Textarea'
import ImageUpload from 'core/components/form/ImageUpload'
import MultiRowWrapper from 'core/components/form/MultiRowWrapper'

export const fieldDefaults = {
	'items': []
}

class ImageSelectorItemForm extends Component {
	render() {
		return (
			<div className="repeater">
				
				{this.props.rows.map((item, i) => {
					return (
						<div className="repeater-section" key={i}>
							<ImageUpload name="image" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={item.image._id} />
							<Input type="text" name="title" label="Image Title" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={item.title} />
							
							<button type="button" className="remove" onClick={() => this.props.removeRow(i)}></button>
						</div>
					)
				})}
				<button type="button" className="add" onClick={() => this.props.addRow()}>
					Add Bucket <span>+</span>
				</button>
			</div>
		)
	}
} 

const ImageSelectorItemField = MultiRowWrapper(ImageSelectorItemForm, {
		image: {},
        title: '',
    });

export class ImageSelector extends Component {
	render() {
		return (
			<div className="block">
				<p className="block-title">Image Selector</p>
				<div className="block-fields">
					<ImageSelectorItemField name="items" onChange={this.props.onChange} rows={this.props.content.items} />
				</div>
			</div>
		);
	}
}