import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import WYSIWYG from 'core/components/form/WYSIWYG'
import ImageUpload from 'core/components/form/ImageUpload'
import DatePicker from 'core/components/form/DatePicker'
import moment from 'moment'
import Post from 'frontend/modules/Post/components/Post'


class PostForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			featuredimage: null
		}
	}
	setImageState(name, value) {
		this.setState({
			[name]: value
		})
		this.props.onInputChange(name, value._id)
	}
	render() {
		const { values, errors } = this.props

		return (
			<div className="main-content">
				<form className="login-form" onSubmit={this.props.onSubmit}>
					<fieldset>
						<p className="form-title">Post</p>
						<Input type="text" name="title" label="Title" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.title} errors={errors.title} />
						<Input type="text" name="slug" label="Slug" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.slug} errors={errors.slug} />
					    <WYSIWYG name="content" label="Content" onInputChange={this.props.onInputChange} errors={errors.content} value={values.content} />
					    <ImageUpload name="featuredimage" onInputChange={(name, value) => this.setImageState(name, value)} errors={errors.featuredimage} value={values.featuredimage} />  
					    <DatePicker type="text" name="publish_start" label="Publish On" onInputChange={this.props.onInputChange} errors={errors.publish_start} value={values.publish_start} showTimeSelect />
					</fieldset>
				</form>
				{ 
					this.props.preview ? 
					<div className="page-preview-wrapper">
						<Post featuredImage={this.state.featuredimage} title={values.title} content={values.content} />
						<button className="button close-preview-button" onClick={this.props.togglePreview}>Close Preview</button>
					</div>
					: ''
				}
			</div>
		)
	}
}

export default PostForm;